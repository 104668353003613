import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { ErrorOption, FieldPath, FieldValues } from 'react-hook-form';
import { ServiceState } from 'services/types/ServiceStateTypes';

export interface DropDownOption {
  text: string;
}

export enum FieldType {
  DROP_DOWN = 'DROP_DOWN',
  TEXT = 'TEXT',
  DATE = 'DATE',
}

export interface DropDownField {
  type: FieldType.DROP_DOWN;
  dropDownOption: DropDownOption[];
}

export interface TextField {
  type: FieldType.TEXT;
}

export type FieldInfo = DropDownField | TextField;

export interface UIAdditionalInfoField {
  fieldValue?: string;
  fieldName: string;
  fieldId: string;
  helperText: string;
  isRequiredAtReservation: boolean;
  fieldInfo: FieldInfo;
  ordinal?: number;
  dataType?: string;
  isOnlyForBusinessPayer?: boolean;
}

export enum AdditionalInfoAccountType {
  RATE_SOURCE,
  BILL_TO,
  SAME_AS_RATE_SOURCE,
}

type BaseAdditionalInfoData = {
  accountNumber: string;
  additionalInfoFields: UIAdditionalInfoField[];
  accountName?: string;
  accountUrn?: string;
};

type RateSourceAdditionalInfoData = BaseAdditionalInfoData & {
  type: AdditionalInfoAccountType.RATE_SOURCE;
};

export type BillToAdditionalInfoData = BaseAdditionalInfoData & {
  type: AdditionalInfoAccountType.BILL_TO;
  billingNumber?: string;
};

export type SameAsRateSourceAdditionalInfo = BaseAdditionalInfoData & {
  type: AdditionalInfoAccountType.SAME_AS_RATE_SOURCE;
  billingNumber?: string;
};

export type AdditionalInfoData =
  | RateSourceAdditionalInfoData
  | BillToAdditionalInfoData
  | SameAsRateSourceAdditionalInfo;

export type AdditionalInformationTypes = {
  fieldId: string;
  value: string;
  isOnlyForBusinessPayer: boolean;
};

export type SaveRequestsTypes = {
  account: string;
  type: AdditionalInfoAccountType;
  additionalInformation: AdditionalInformationTypes[];
  accountNumber: string;
  billingNumber: string;
};

export type EditAdditionalInfoProps = {
  additionalInfoData: AdditionalInfoData[];
  open: boolean;
  onClose: (selectedAction: SelectedAction) => void;
};

export type SaveAdditionalInfoHookReturn = {
  saveAdditionalInfo: (
    saveAdditionalInfo: SaveAdditionalInfoAccountData[],
    setError: (
      name: FieldPath<FieldValues> | `root.${string}` | 'root',
      error: ErrorOption,
      options?: { shouldFocus: boolean }
    ) => void
  ) => Promise<boolean>;
};

export type AdditionalInfoHookReturn = ServiceState & {
  additionalInfo: AdditionalInfoData[];
};

export type SaveAdditionalInfoAccountData = SaveRateSourceAccountAdditionalInfo | SaveBillToAccountAdditionalInfo;

interface BaseSaveAccountAdditionalInfoData {
  fields: AdditionalInformationTypes[];
  accountNumber: string;
  accountUrn: string;
  billingNumber: string;
}

export interface SaveRateSourceAccountAdditionalInfo extends BaseSaveAccountAdditionalInfoData {
  type: AdditionalInfoAccountType.RATE_SOURCE;
}

export interface SaveBillToAccountAdditionalInfo extends BaseSaveAccountAdditionalInfoData {
  type: AdditionalInfoAccountType.BILL_TO | AdditionalInfoAccountType.SAME_AS_RATE_SOURCE;
  ordinal: number;
}
