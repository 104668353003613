import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  AddPreferencesFields,
  AddPreferencesValues,
} from 'components/notes/notesTab/cards/preferenceCard/AddPreferenceTypes';
import { EMPTY_VALUE } from 'utils/constants';
import { AnyObject, ObjectSchema } from 'yup';

export const addPreferencesSchema = (
  t: TFunction<'translation'>
): ObjectSchema<{ preferences: string | undefined }, AnyObject, { preferences: undefined }, ''> =>
  Yup.object().shape({
    preferences: Yup.string().containsCreditCardPattern(t('validation.notesCreditCardNotAllowed')),
  });

export const addPreferencesInitialValues = (preferencesSet: string): AddPreferencesValues => ({
  [AddPreferencesFields.Preferences]: preferencesSet ? preferencesSet : EMPTY_VALUE,
});
