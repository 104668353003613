import { EditorIssue } from 'services/booking/bookingTypes';
import {
  deleteVehicleClassSelection,
  removeRenter,
  updateAllAddOns,
  updateBusinessPayers,
} from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectDriverProfileRenter,
  selectEquipment,
  selectProtections,
} from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  getEhiIssuesAsSnackbarMessages,
  getEhiMessagesAsSnackbarMessages,
  getPersistedEquipment,
  getPersistedProtections,
  hasBillToBookingIssues,
  hasDriverBookingIssue,
  hasPeoBookingIssues,
  hasVehicleAvailabilityBookingIssues,
  hasVehicleYoungDriverBookingIssue,
} from 'utils/bookingUtils';
import { transformToEditorAddOnsRequestBody } from 'services/booking/bookingTransformer';
import { AvailableBookingIssue } from 'hooks/bookingEditor/useRefreshEditor';
import { uniqBy } from 'lodash';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { useCallback } from 'react';
import { DescriptionWithAction } from 'components/shared/alert/AlertDialogTypes';
import { To, useNavigate } from 'react-router-dom';
import { useTranslations } from 'components/shared/i18n';

export type BookingIssueReturn = {
  handleRemoveVehicle: (issues: EditorIssue[]) => Promise<void>;
  handleRemoveAddOns: (
    issues: EditorIssue[],
    persistedEhiMessages?: ResponseMessage[],
    availableBookingIssues?: AvailableBookingIssue[] | undefined
  ) => Promise<void>;
  handleRemoveDriverProfile: (issues: EditorIssue[]) => Promise<void>;
  handleRemoveBillTo: (issues: EditorIssue[]) => Promise<void>;
};

export const useBookingIssue = (): BookingIssueReturn => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { setSnackBarRes } = useResSnackbarContext();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const equipment = useAppSelector(selectEquipment);
  const protections = useAppSelector(selectProtections);
  const driverProfile = useAppSelector(selectDriverProfileRenter);

  const handleViewAction = useCallback(
    (snackbarMessages: DescriptionWithAction[]): (() => void) | undefined => {
      if (snackbarMessages.length === 1) {
        const routePath: To | undefined = snackbarMessages[0].routePath;
        return routePath ? (): void => navigate(routePath) : undefined;
      } else if (snackbarMessages.length > 1) {
        return async () =>
          showAlert({
            variant: 'warning',
            title: t('snackbarMessages.multipleChangesOccurred'),
            descriptions: snackbarMessages,
            navigate: navigate,
          });
      }

      return undefined;
    },
    [navigate, showAlert, t]
  );

  const handleShowingSnackbar = useCallback(
    (editorIssues: EditorIssue[], ehiMessages: ResponseMessage[], availableBookingIssues: AvailableBookingIssue[]) => {
      const allSnackbarMessages = uniqBy(
        [
          ...getEhiMessagesAsSnackbarMessages(ehiMessages, []),
          ...getEhiIssuesAsSnackbarMessages(editorIssues, availableBookingIssues ?? []),
        ],
        'message'
      );

      if (allSnackbarMessages.length > 0) {
        setSnackBarRes({
          onViewAction: handleViewAction(allSnackbarMessages),
          message:
            allSnackbarMessages.length > 1
              ? t('snackbarMessages.multipleChangesOccurred')
              : allSnackbarMessages[0].message,
          isOpen: true,
        });
      }
    },
    [handleViewAction, setSnackBarRes, t]
  );

  const handleRemoveVehicle = async (issues: EditorIssue[]): Promise<void> => {
    if (hasVehicleYoungDriverBookingIssue(issues) || hasVehicleAvailabilityBookingIssues(issues)) {
      const hasSelectedAddons = !!(equipment || protections);
      await updateAndRefresh(async () => {
        await deleteVehicleClassSelection(bookingEditorId);
        if (hasSelectedAddons) {
          const requestBody = transformToEditorAddOnsRequestBody([], []);
          await updateAllAddOns(bookingEditorId, requestBody);
        }
      }).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
      });
    }
  };

  const handleRemoveAddOns = async (
    issues: EditorIssue[],
    persistedEhiMessages?: ResponseMessage[],
    availableBookingIssues?: AvailableBookingIssue[] | undefined
  ): Promise<void> => {
    if (hasPeoBookingIssues(issues)) {
      const requestBody = transformToEditorAddOnsRequestBody(
        getPersistedEquipment(issues, equipment ?? []),
        getPersistedProtections(issues, protections ?? [])
      );
      await updateAndRefresh(async () => {
        await updateAllAddOns(bookingEditorId, requestBody);
      }).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
        // Placing this check here temporary so this all applies to useSaveWhenAndWhere
        // There will be a future clean up story to move setting the snackbar in this hook
        if (availableBookingIssues) {
          handleShowingSnackbar(
            issues,
            [...(persistedEhiMessages ?? []), ...(result.data.ehiMessages ?? [])],
            availableBookingIssues
          );
        }
      });
    }
  };

  const handleRemoveDriverProfile = async (issues: EditorIssue[]): Promise<void> => {
    if (hasDriverBookingIssue(issues) && driverProfile?.profile) {
      await updateAndRefresh(async () => removeRenter(bookingEditorId)).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
      });
    }
  };

  const handleRemoveBillTo = async (issues: EditorIssue[]): Promise<void> => {
    if (hasBillToBookingIssues(issues)) {
      await updateAndRefresh(async () => updateBusinessPayers(bookingEditorId, [])).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
      });
    }
  };

  return {
    handleRemoveAddOns,
    handleRemoveVehicle,
    handleRemoveDriverProfile,
    handleRemoveBillTo,
  };
};
