import { FC, useMemo } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { addNotesInitialValues, addNotesSchema } from 'components/notes/addNote/addNotesUtils';
import { AddNotesFields, AddNotesValues } from 'components/notes/addNote/AddNotesTypes';
import { FormProvider, useForm } from 'react-hook-form';

import { AddNotesTextBox } from 'components/shared/notes/NotesButton.styles';
import { ehiTheme } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { Box } from '@mui/material';
import { TextAreaField } from 'components/shared/forms/TextAreaField';

interface AddNotesDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AddNotesDialog: FC<AddNotesDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslations();
  const resolver = useYupValidationResolver(addNotesSchema(t));
  const NOTES_MAX_CHARS = 500;

  const formInitialValues: AddNotesValues = useMemo(() => addNotesInitialValues(), []);

  const formMethods = useForm<AddNotesValues>({
    resolver: resolver,
    defaultValues: formInitialValues,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const notes = formMethods.watch(AddNotesFields.Notes);

  const handleSubmit = async (): Promise<void> => {
    // Will be implemented in a later story
    console.log('Testing Add Note Submit Button');
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        data-testid='addNoteDialog'
        contentPadding={0}
        open={open}
        a11yKey='content'
        title={t('notes.addNote')}
        maxWidth={'sm'}
        fullWidth
        actions={{
          secondaryAction: {
            label: t('common.cancel'),
            onClick: onClose,
          },
          primaryAction: {
            label: t('common.submit'),
            onClick: formMethods.handleSubmit(handleSubmit),
            overrideButtonProps: { disabled: notes === '' },
          },
        }}>
        <Box style={{ paddingTop: ehiTheme.spacing(0.5) }}>
          <AddNotesTextBox>
            <TextAreaField
              header={t('notes.addNote')}
              displayHeader={false}
              name={AddNotesFields.Notes}
              maxChars={NOTES_MAX_CHARS}
              rows={12}
              marginBottom={'0'}
            />
          </AddNotesTextBox>
        </Box>
      </Dialog>
    </FormProvider>
  );
};
