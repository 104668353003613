import { useCallback } from 'react';
import { WhenAndWhereFields, WhenAndWhereValues } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { updatePickupInformation, updateReturnInformation } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectEquipment,
  selectProtections,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { useTranslations } from 'components/shared/i18n';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import {
  getAllBillToBookingIssues,
  getAllVehicleNotAvailableBookingIssues,
  getPeoBookingIssues,
  hasVehicleAvailabilityBookingIssues,
} from 'utils/bookingUtils';
import { useEquipmentTypesQuery, useProtectionTypesQuery } from 'services/rentalReference/rentalReferenceQueries';

export type SavePickupReturn = {
  save: (data: WhenAndWhereValues) => Promise<ServiceResultType>;
  handleErrors: (errors: ResponseMessage[]) => void;
};

export const useSaveWhenAndWhere = (): SavePickupReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleRemoveVehicle, handleRemoveBillTo, handleRemoveAddOns } = useBookingIssue();
  const equipment = useAppSelector(selectEquipment);
  const { data: equipmentDetails = [] } = useEquipmentTypesQuery();
  const protections = useAppSelector(selectProtections);
  const { data: protectionDetails = [] } = useProtectionTypesQuery();

  const handleErrors = useCallback(
    async (errors: ResponseMessage[]) => {
      await showAlert({ responseMessages: errors });
    },
    [showAlert]
  );

  const save = useCallback(
    async (values: WhenAndWhereValues) => {
      const pickUpLocation = values[WhenAndWhereFields.StartLocationUrn];
      const returnLocation = values[WhenAndWhereFields.ReturnLocationUrn];
      const startDateTime = values[WhenAndWhereFields.StartDateTime];
      const returnDateTime = values[WhenAndWhereFields.ReturnDateTime];

      let pickUpInfo = {
        branch: pickUpLocation,
      };
      pickUpInfo = { ...pickUpInfo, ...(startDateTime ? { dateTime: startDateTime } : {}) };

      let returnInfo = {
        branch: returnLocation,
      };
      returnInfo = { ...returnInfo, ...(returnDateTime ? { dateTime: returnDateTime } : {}) };

      const availableBookingIssues = [
        ...getAllVehicleNotAvailableBookingIssues(t, 'snackbarMessages.additionalInfo.date', vehicle),
        ...getPeoBookingIssues(
          t,
          t('snackbarMessages.additionalInfo.date'),
          equipment ?? [],
          equipmentDetails,
          protections ?? [],
          protectionDetails
        ),
        ...getAllBillToBookingIssues(t),
      ];
      const { errors, data } = await updateAndRefresh(
        async () => {
          await updatePickupInformation(bookingEditorId, pickUpInfo);
          await updateReturnInformation(bookingEditorId, returnInfo);
        },
        {
          availableBookingIssues: availableBookingIssues,
        }
      );

      if (errors) {
        await handleErrors(errors);
      } else if (data?.issue) {
        if (hasVehicleAvailabilityBookingIssues(data?.issue)) {
          await handleRemoveVehicle(data?.issue);
        } else {
          await handleRemoveAddOns(data?.issue, data.ehiMessages, availableBookingIssues);
        }
        await handleRemoveBillTo(data.issue);
      }
      return { success: !errors, errors: errors };
    },
    [
      updateAndRefresh,
      t,
      vehicle,
      equipment,
      equipmentDetails,
      protections,
      protectionDetails,
      bookingEditorId,
      handleErrors,
      handleRemoveBillTo,
      handleRemoveVehicle,
      handleRemoveAddOns,
    ]
  );

  return { save, handleErrors };
};
