import { EMPTY_VALUE } from 'utils/constants';
import { ConsentFields, ConsentFieldValues } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { isCorporateCountry } from 'utils/locationUtils';
import { AddressType, BranchV2 } from 'services/location/locationTypes';

export const consentInitialValues: ConsentFieldValues = {
  [ConsentFields.Country]: EMPTY_VALUE,
  [ConsentFields.PhoneNumber]: EMPTY_VALUE,
  [ConsentFields.Status]: EMPTY_VALUE,
};

export const displayConsentSection = (
  isAdditionalDriver: boolean | undefined,
  pickup: BranchV2 | undefined
): boolean => {
  return !isAdditionalDriver && isCorporateCountry(pickup?.addresses ?? [], AddressType.PHYSICAL);
};
