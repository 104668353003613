import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import {
  RateAndBillingLabel,
  RateAndBillingSubSectionBox,
  RateAndBillingText,
  RateAndBillingTitleBox,
  StyledEhiEditButton,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Box, Grid } from '@mui/material';
import { H6 } from '@ehi/ui';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';

export const ReferenceInformationSection: FC = () => {
  const { t } = useTranslations();

  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  const handleEditClick = (): void => {
    console.log('Edit Clicked');
  };

  return (
    <>
      <EhiDivider />
      <RateAndBillingSubSectionBox>
        <RateAndBillingTitleBox>
          <H6 bold data-testid='referenceInformation-title'>
            {t('rateAndBilling.referenceInformation')}
          </H6>
          {!isReadOnly && (
            <Box>
              <StyledEhiEditButton
                size='small'
                variant='text'
                onClick={handleEditClick}
                data-testid='reference-information-edit-button'>
                {t('common.edit')}
              </StyledEhiEditButton>
            </Box>
          )}
        </RateAndBillingTitleBox>
        <Grid container>
          <Grid item xs={6} sm={6} data-testid='iataArcClia'>
            <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.iataArcClia')}</RateAndBillingLabel>
            <RateAndBillingText data-testid='textBody'>{''}</RateAndBillingText>
          </Grid>
          <Grid item xs={6} sm={6} data-testid='billingReference'>
            <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.billingReference')}</RateAndBillingLabel>
            <RateAndBillingText data-testid='textBody'>{''}</RateAndBillingText>
          </Grid>
        </Grid>
      </RateAndBillingSubSectionBox>
    </>
  );
};
