import { BillingAccountType } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { RootState } from 'redux/store';
import {
  AdditionalDriver,
  AdditionalInformation,
  AlternateReferences,
  AppliedAncillaryFees,
  AvailableProtection,
  BillingAccountPayer,
  Charges,
  Contact,
  DriverProfileRenter,
  EditorAddOns,
  EditorBusinessPayer,
  EditorIssue,
  EditorPayers,
  Equipment,
  GeneralCondition,
  Pickup,
  PointOfSale,
  Protection,
  RateSource,
  Renter,
  Return,
  Source,
  TransactionalProfileRenter,
  VehicleClassSelection,
} from 'services/booking/bookingTypes';
import { ReservationData } from 'components/shared/uiModels/reservation/reservationTypes';
import { isOneWay } from 'utils/locationUtils';
import { isEmpty } from 'utils/stringUtils';
import { parseUrn } from 'utils/urnUtils';
import { Brand } from 'utils/constants';

/**
 * Try to avoid using this one unless you really need a lot of the data from store at a time.
 * Instead, use other selectors
 */
export const selectReservationData = ({ bookingEditor }: RootState): ReservationData | undefined => {
  return bookingEditor?.reservation;
};

export const selectBrand = ({ bookingEditor }: RootState): string | undefined => {
  return bookingEditor?.reservation?.brand;
};

export const selectCharges = ({ bookingEditor }: RootState): Charges | undefined => {
  return bookingEditor?.reservation?.charges;
};

export const selectRenter = ({ bookingEditor }: RootState): Renter | undefined => {
  return bookingEditor?.reservation?.renter;
};

export const selectDriverProfileRenter = ({ bookingEditor }: RootState): DriverProfileRenter | undefined => {
  return bookingEditor?.reservation?.renter as DriverProfileRenter;
};

export const selectTransactionalProfileRenter = ({
  bookingEditor,
}: RootState): TransactionalProfileRenter | undefined => {
  return bookingEditor?.reservation?.renter as TransactionalProfileRenter;
};

export const selectAdditionalDrivers = ({ bookingEditor }: RootState): AdditionalDriver[] | undefined => {
  return bookingEditor?.reservation?.additionalDriver;
};

export const selectContact = ({ bookingEditor }: RootState): Contact | undefined => {
  return bookingEditor?.reservation?.contact;
};

export const selectPickup = ({ bookingEditor }: RootState): Pickup | undefined => {
  return bookingEditor?.reservation?.pickup;
};

export const selectReturn = ({ bookingEditor }: RootState): Return | undefined => {
  return bookingEditor?.reservation?.return;
};

export const selectRateSource = ({ bookingEditor }: RootState): RateSource | undefined => {
  return bookingEditor?.reservation?.rateSource;
};

export const selectVehicleClassSelection = ({ bookingEditor }: RootState): VehicleClassSelection | undefined => {
  return bookingEditor?.reservation?.vehicleClassSelection;
};

export const selectAddons = ({ bookingEditor }: RootState): EditorAddOns | undefined => {
  return bookingEditor?.reservation?.addOns;
};

export const selectAvailableEquipment = ({ bookingEditor }: RootState): Equipment[] | undefined => {
  if (bookingEditor?.reservation?.addOns) {
    return bookingEditor.reservation?.addOns.equipment?.available;
  }
  return undefined;
};

export const selectEquipment = ({ bookingEditor }: RootState): Equipment[] | undefined => {
  if (bookingEditor?.reservation?.addOns) {
    return bookingEditor.reservation?.addOns.equipment?.selected;
  }
  return undefined;
};

export const selectAvailableProtections = ({ bookingEditor }: RootState): AvailableProtection | undefined => {
  if (bookingEditor?.reservation?.addOns) {
    return bookingEditor.reservation?.addOns.protections?.available;
  }
  return undefined;
};

export const selectProtections = ({ bookingEditor }: RootState): Protection[] | undefined => {
  if (bookingEditor?.reservation?.addOns) {
    return bookingEditor.reservation?.addOns.protections?.selected;
  }
  return undefined;
};

export const selectAppliedAncillaryFees = ({ bookingEditor }: RootState): AppliedAncillaryFees | undefined => {
  if (bookingEditor?.reservation?.addOns) {
    return bookingEditor.reservation?.addOns.ancillaryFees?.applied;
  }
  return undefined;
};

export const selectBookingEditorId = ({ bookingEditor }: RootState): string => {
  return bookingEditor?.editorId || '';
};

export const selectIsReadOnlyFlow = ({ bookingEditor }: RootState): boolean => {
  return bookingEditor?.reservation?.dataType === 'Reservation';
};

export const selectGeneralConditions = ({ bookingEditor }: RootState): GeneralCondition[] | undefined => {
  return bookingEditor?.reservation?.generalConditions;
};

export const selectBookingEditorIssues = ({ bookingEditor }: RootState): EditorIssue[] | undefined => {
  return bookingEditor?.reservation?.issue;
};

export const selectLengthOfRental = ({ bookingEditor }: RootState): number | undefined => {
  return bookingEditor?.reservation?.charges?.calculatedDurationDays;
};

export const selectIsBookingEditorDirty = ({ bookingEditor }: RootState): boolean => {
  return bookingEditor?.isDirty;
};

export const selectHasLocationData = ({ bookingEditor }: RootState): boolean => {
  return !isEmpty(bookingEditor?.reservation?.pickup?.branch) && !isEmpty(bookingEditor?.reservation?.return?.branch);
};

export const selectHasDatetimeData = ({ bookingEditor }: RootState): boolean => {
  return (
    !isEmpty(bookingEditor?.reservation?.pickup?.dateTime) && !isEmpty(bookingEditor?.reservation?.return?.dateTime)
  );
};

export const selectOneWay = ({ bookingEditor }: RootState): boolean => {
  return isOneWay(bookingEditor?.reservation?.pickup?.branch, bookingEditor?.reservation?.return?.branch);
};

export const selectPayers = ({ bookingEditor }: RootState): EditorPayers | undefined => {
  return bookingEditor?.reservation?.payers;
};

export const selectBillingAccountPayer = ({ bookingEditor }: RootState): string | undefined => {
  const businessPayer: BillingAccountPayer | undefined = bookingEditor?.reservation?.payers?.business?.find(
    (businessPayer: EditorBusinessPayer) => businessPayer.type === BillingAccountType.BILLING_ACCOUNT
  ) as BillingAccountPayer;
  return businessPayer?.billingAccount;
};

export const selectBillingAccountAdditionalInformation = ({
  bookingEditor,
}: RootState): AdditionalInformation[] | undefined => {
  const businessPayer: BillingAccountPayer | undefined = bookingEditor?.reservation?.payers?.business?.find(
    (businessPayer: EditorBusinessPayer) => businessPayer.type === BillingAccountType.BILLING_ACCOUNT
  ) as BillingAccountPayer;

  return businessPayer?.additionalInformation;
};

export const selectIsReservation = ({ bookingEditor }: RootState): boolean => {
  return bookingEditor?.reservation?.dataType === 'Reservation' || !!bookingEditor?.editorId;
};

export const selectSource = ({ bookingEditor }: RootState): Source | undefined => {
  return bookingEditor?.reservation?.source;
};

export const selectPointOfSale = ({ bookingEditor }: RootState): PointOfSale | undefined => {
  return bookingEditor?.reservation?.pointOfSale;
};

export const selectAlternateReferences = ({ bookingEditor }: RootState): AlternateReferences | undefined => {
  return bookingEditor?.reservation?.alternateReferences;
};

export const selectPriorReservationNumber = ({ bookingEditor }: RootState): string | undefined => {
  return bookingEditor?.reservation?.priorReservationNumber;
};

export const selectRemarks = ({ bookingEditor }: RootState): string => {
  return bookingEditor?.reservation?.remark ?? '';
};

export const selectEnterpriseBrandAndHasDateTime = ({ bookingEditor }: RootState): boolean => {
  return (
    !isEmpty(bookingEditor?.reservation?.brand) &&
    parseUrn(bookingEditor?.reservation?.brand) === Brand.ENTERPRISE &&
    !isEmpty(bookingEditor?.reservation?.pickup?.dateTime) &&
    !isEmpty(bookingEditor?.reservation?.return?.dateTime)
  );
};
