import { useCallback } from 'react';
import { updatePayersPerson } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { PaymentFields, PaymentValues } from 'components/flexFlow/payment/PaymentTypes';
import { EHI_DOMAINS, generateReferenceUrn } from 'utils/urnUtils';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { EMPTY_VALUE } from 'utils/constants';
import { getAllVehicleNotAvailableBookingIssues, hasVehicleAvailabilityBookingIssues } from 'utils/bookingUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useTranslations } from 'components/shared/i18n';

export type SavePayment = {
  save: (data: PaymentValues) => Promise<ServiceResultType>;
};

export const useSavePayment = (): SavePayment => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleRemoveVehicle } = useBookingIssue();

  const save = useCallback(
    async (values: PaymentValues) => {
      const paymentValue = values[PaymentFields.PaymentType];
      const appConfig = getAppConfigCache();
      const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? EMPTY_VALUE;
      const payload = {
        paymentMethod: generateReferenceUrn(
          EHI_DOMAINS.rental.name,
          EHI_DOMAINS.rental.booking + ':' + EHI_DOMAINS.rental.person + ':' + EHI_DOMAINS.rental.paymentMethod,
          paymentValue,
          defaultEhiDatabase
        ),
        cardProvided: false,
      };

      const { errors, data } = await updateAndRefresh(
        async () => {
          await updatePayersPerson(bookingEditorId, payload);
        },
        {
          availableBookingIssues: [
            ...getAllVehicleNotAvailableBookingIssues(t, 'snackbarMessages.additionalInfo.paymentType', vehicle),
          ],
        }
      );

      if (errors) {
        await showAlert({ responseMessages: errors });
      } else if (data?.issue && hasVehicleAvailabilityBookingIssues(data.issue)) {
        await handleRemoveVehicle(data.issue);
      }
      return { success: !errors, errors: errors };
    },
    [bookingEditorId, handleRemoveVehicle, showAlert, t, updateAndRefresh, vehicle]
  );

  return { save };
};
