import { FC, useCallback, useState } from 'react';
import { AppBar, Box } from '@mui/material';
import { DriverSearchForm } from 'components/flexFlow/driver/driverSearch/DriverSearchForm';
import { useRenterProfileSearch } from 'components/flexFlow/driver/driverSearch/useRenterProfileSearch';
import { DriverSearchProps, DriverSearchValues, SelectedDriverProfile } from './DriverSearchTypes';
import { MarginWrapper, StyledList } from 'components/shared/ui/styles/Global.styles';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import DriverSearchResult from './DriverSearchResult';
import { LoyaltyMembership, SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { useTranslations } from 'components/shared/i18n';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { RateSource, Renter } from 'services/booking/bookingTypes';
import {
  associateRenterToReservationEditor,
  modifyRateSource,
  updateAdditionalDrivers,
  updatePayersPerson,
} from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectBrand,
  selectContact,
  selectDriverProfileRenter,
  selectPayers,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { calculateAge, toDateTimeString } from 'utils/dateUtils';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import { ADD, parseContactName, REMOVE } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { Body1, Body2, ehiTheme } from '@ehi/ui';
import { addPaymentMethodByLoyaltyMembership, prioritizeByBrand } from './driverSearchUtils';
import {
  CURRENT,
  DRIVER_PROFILE,
  LOYALTY,
  PRIMARY_DRIVER,
  RATE_SOURCE,
} from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { parseUrn } from 'utils/urnUtils';
import { Trans } from 'react-i18next';
import { StyledLink } from 'components/shared/ui/noResultsView/NoResultsViewStyles';
import { getDriverProfileLoyaltyMembership } from 'services/renter/driverProfile/driverProfileService';
import { retrieveBusinessAccount } from 'services/businessAccount/businessAccountService';
import { EMPTY_VALUE } from 'utils/constants';
import { RenterWarningDialog } from 'components/flexFlow/driver/driverSearch/renterWarning/RenterWarningDialog';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { ACCOUNT_TYPE_NEGOTIATED } from 'utils/rateAndBillingUtils';
import {
  getVehicleYoungDriverBookingIssue,
  hasVehicleYoungDriverBookingIssue,
  RateProductIsModifiedBookingIssue,
} from 'utils/bookingUtils';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { useDriverLicenseValidation } from 'hooks/driverLicenseValidation/useDriverLicenseValidation';
import { ERROR_ALERT_VARIANT } from 'components/shared/alert/AlertDialogTypes';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { safelyCatchError } from 'utils/errorUtils';

export const DriverSearch: FC<DriverSearchProps> = ({
  navigateToDriverForm,
  onClose,
  isUpdatingPrimaryDriver = false,
}) => {
  const { t } = useTranslations();
  const { search } = useRenterProfileSearch();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const { handleRemoveVehicle } = useBookingIssue();
  const appConfig = getAppConfigCache();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const contact = useAppSelector(selectContact);
  const payers = useAppSelector(selectPayers);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[] | undefined>();
  const [driverData, setDriverData] = useState<DriverData | undefined>(undefined);
  const [dialogType, setDialogType] = useState<null | typeof PRIMARY_DRIVER | typeof RATE_SOURCE>(null);
  const [selectedDriverProfile, setSelectedDriverProfile] = useState<SelectedDriverProfile | undefined>(undefined);
  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading } = usePhoneTypesQuery();
  const selectedBrand = useAppSelector(selectBrand);
  const [showRenterWarningDialog, setShowRenterWarningDialog] = useState<SearchResult | undefined>(undefined);
  const { validateExpirationDateAgainstRentalDates } = useDriverLicenseValidation();

  const handleRenterWarningDialog = (state: SearchResult | undefined): void => {
    setShowRenterWarningDialog(state);
  };

  const searchForRenter = useCallback(
    async (values: DriverSearchValues): Promise<SearchResult[] | undefined> => {
      const validateDrivers = (urn: string): boolean => {
        const prevDrivers = [...(driverProfileRenter ? [driverProfileRenter] : []), ...(additionalDrivers || [])];
        return prevDrivers.some(({ profile }) => profile === urn);
      };

      setLoading(true);
      return search(values)
        .then(async (results) => {
          const sortedResults = prioritizeByBrand(results, parseUrn(selectedBrand));
          const duplicateDriver = sortedResults.some((result) => result.urn && validateDrivers(result.urn));
          if (duplicateDriver) {
            await showAlert({
              variant: 'error',
              description: `${t('driverSearch.driverAlreadyExists')}`,
            });
            return;
          }

          setResults(sortedResults);
          return sortedResults;
        })
        .catch(() => {
          setResults([]);
          return [];
        })
        .finally(() => {
          if (values) {
            const dobString = toDateTimeString(values.dateOfBirth, t('format.yearMonthDay')) ?? undefined;
            setDriverData({
              phoneNumbers: [{ number: values.phoneNumber }],
              firstName: values.firstName,
              lastName: values.lastName,
              age: dobString ? calculateAge(dobString) : undefined,
            });
          }
          setLoading(false);
        });
    },
    [search, driverProfileRenter, additionalDrivers, selectedBrand, showAlert, t]
  );

  const addAdditionalDrivers = async (driverData: SearchResult): Promise<void> => {
    try {
      const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
      const requestBody = [
        ...currentAdditionalDrivers,
        {
          name: {
            given: driverData.name?.givenName ?? EMPTY_VALUE,
            surname: driverData.name?.surname ?? EMPTY_VALUE,
          },
          profile: driverData.urn,
        },
      ];
      const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
      if (errors) {
        await showAlert({ responseMessages: errors });
      } else {
        driverData.legalIdentifications &&
          driverData.legalIdentifications.length > 0 &&
          driverData.legalIdentifications[0].expirationDate &&
          validateExpirationDateAgainstRentalDates(driverData.legalIdentifications[0].expirationDate);
        onClose();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddDriver = async (driverData: SearchResult): Promise<void> => {
    setLoading(true);

    if (!driverProfileRenter || isUpdatingPrimaryDriver) {
      // Retrieve selected primary driver's business account. If valid, will either add to editor if initial primary
      // driver or will store in state if updating primary driver
      const membershipProgram = driverData?.loyaltyMembership?.loyaltyProgram;
      let accountDetails: ReservationAccount | undefined;
      let alternateContractId: string | undefined;
      if (membershipProgram && driverData.urn) {
        try {
          const membership: LoyaltyMembership = await getDriverProfileLoyaltyMembership(
            parseUrn(driverData.urn),
            parseUrn(membershipProgram)
          );
          if (membership) {
            alternateContractId = membership?.rateContracts?.[0]?.alternateContractId;
            if (alternateContractId) {
              accountDetails = await retrieveBusinessAccount(alternateContractId);
            }
          }
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          if (!accountDetails) {
            onClose();
            await showAlert({
              variant: 'error',
              description: (
                <Box display={'flex'}>
                  <ErrorOutlineIcon color={ERROR_ALERT_VARIANT} style={{ paddingRight: ehiTheme.spacing(1) }} />
                  <Box>
                    <Body1>{t('error.serviceCallError')}</Body1>
                    <Body2 color={'#000000de'}>
                      {t('driverSearch.rateSourceError', { accountNumber: alternateContractId })}
                    </Body2>
                  </Box>
                </Box>
              ),
            });
          } else {
            await showAlert({ responseMessages: ehiErrorsResponse.errors });
          }
        }
      }

      if (isUpdatingPrimaryDriver) {
        setSelectedDriverProfile({
          urn: driverData.urn,
          loyaltyMembership: driverData.loyaltyMembership,
          accountDetails: accountDetails,
        });
        if (accountDetails) {
          setDialogType(RATE_SOURCE);
        } else {
          setDialogType(PRIMARY_DRIVER);
        }
        setShowRenterWarningDialog(undefined);
        setLoading(false);
        return;
      }

      try {
        const requestBody = {
          type: DRIVER_PROFILE,
          profile: driverData.urn,
          membership: membershipProgram ? { loyaltyProgram: membershipProgram } : undefined,
        } as Renter;

        const { errors, data } = await updateAndRefresh(
          async () => {
            await associateRenterToReservationEditor(bookingEditorId, requestBody as Renter);

            if (accountDetails) {
              const rateSourceRequestBody = {
                type: ACCOUNT_TYPE_NEGOTIATED,
                account: accountDetails.urn ?? EMPTY_VALUE,
              } as RateSource;
              await modifyRateSource(bookingEditorId, rateSourceRequestBody as RateSource);
            }
            // Update the editor when payment method is not selected and
            // Driver loyalty profile has a payment method as credit card,
            const existingPaymentMethod = parseUrn(payers?.person?.paymentMethod);
            const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
            const paymentMethodPayload = addPaymentMethodByLoyaltyMembership(
              existingPaymentMethod,
              defaultEhiDatabase,
              driverData?.loyaltyMembership
            );

            if (paymentMethodPayload) {
              await updatePayersPerson(bookingEditorId, paymentMethodPayload);
            }
          },
          {
            availableBookingIssues: [
              getVehicleYoungDriverBookingIssue(t, vehicle?.preferred ? parseUrn(vehicle.preferred) : EMPTY_VALUE),
            ],
          }
        );

        if (errors) {
          onClose();
          await showAlert({ responseMessages: errors });
        } else {
          driverData.legalIdentifications &&
            driverData.legalIdentifications.length > 0 &&
            driverData.legalIdentifications[0].expirationDate &&
            validateExpirationDateAgainstRentalDates(driverData.legalIdentifications[0].expirationDate);
          onClose();
          if (data?.issue && hasVehicleYoungDriverBookingIssue(data.issue)) {
            await handleRemoveVehicle(data.issue);
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      await addAdditionalDrivers(driverData);
    }
  };

  const handleConfirmChangePrimaryDriver = async (selection: string): Promise<void> => {
    setDialogType(null);

    if (selectedDriverProfile?.urn) {
      try {
        setLoading(true);

        // Sets new primary driver
        const primaryDriver: Renter = {
          type: DRIVER_PROFILE,
          profile: selectedDriverProfile?.urn,
          membership: selectedDriverProfile?.loyaltyMembership?.loyaltyProgram
            ? { loyaltyProgram: selectedDriverProfile.loyaltyMembership.loyaltyProgram }
            : undefined,
        };

        // Handles old primary driver by adding or removing as additional driver, depending on user selection
        const { given, surname } = parseContactName(contact?.name ?? EMPTY_VALUE);
        const currentAdditionalDrivers = additionalDrivers?.length ? additionalDrivers : [];
        const updatedAdditionalDrivers = currentAdditionalDrivers.filter(
          (additionalDriver) => additionalDriver.profile !== selectedDriverProfile?.urn
        );

        if (selection !== REMOVE) {
          updatedAdditionalDrivers.push({
            name: { given, surname },
            profile: driverProfileRenter?.profile,
          });
        }

        const { errors } = await updateAndRefresh(
          async () => {
            // updating additional driver
            if (updatedAdditionalDrivers) {
              await updateAdditionalDrivers(bookingEditorId, updatedAdditionalDrivers);
            }

            // updating primary driver
            if (primaryDriver) {
              await associateRenterToReservationEditor(bookingEditorId, primaryDriver as Renter);

              // Will update rate source if user selects to update rate source on confirmation dialog
              if (selectedDriverProfile?.accountDetails) {
                const rateSourceRequestBody = {
                  type: ACCOUNT_TYPE_NEGOTIATED,
                  account: selectedDriverProfile.accountDetails?.urn ?? EMPTY_VALUE,
                } as RateSource;
                await modifyRateSource(bookingEditorId, rateSourceRequestBody as RateSource);
              }
              // Update the editor when payment method is not selected and
              // Driver loyalty profile has a payment method as credit card,
              const existingPaymentMethod = parseUrn(payers?.person?.paymentMethod);
              const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
              const paymentMethodPayload = addPaymentMethodByLoyaltyMembership(
                existingPaymentMethod,
                defaultEhiDatabase,
                selectedDriverProfile?.loyaltyMembership
              );

              if (paymentMethodPayload) {
                await updatePayersPerson(bookingEditorId, paymentMethodPayload);
              }
            }
          },
          {
            excludedBookingIssues: selectedDriverProfile?.accountDetails ? [RateProductIsModifiedBookingIssue] : [],
          }
        );
        if (errors) {
          await showAlert({ responseMessages: errors });
        } else {
          onClose();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelChangePrimaryDriver = (): void => {
    setDialogType(null);
  };

  const handleModifyRateSelection = async (selection: string): Promise<void> => {
    if (selection !== LOYALTY) {
      // This will keep the same rate source that is currently on editor
      setSelectedDriverProfile({
        urn: selectedDriverProfile?.urn,
        loyaltyMembership: selectedDriverProfile?.loyaltyMembership,
        accountDetails: undefined,
      });
    }

    setDialogType(PRIMARY_DRIVER);
  };

  const dialogConfig =
    dialogType === RATE_SOURCE
      ? {
          title: t('driver.confirmRateSource'),
          description: t('driver.confirmRateSourcePrompt'),
          options: [
            { label: t('driver.keepCurrentRateSource'), value: CURRENT },
            { label: t('driver.useLoyaltyRateSource'), value: LOYALTY },
          ],
          onConfirm: handleModifyRateSelection,
          onCancel: handleCancelChangePrimaryDriver,
          defaultSelection: CURRENT,
        }
      : {
          title: t('driver.changePrimaryDriver'),
          description: t('driver.additionalDrivers.changePrimaryPrompt'),
          options: [
            { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
            { label: t('driver.removeFromReservation'), value: REMOVE },
          ],
          onConfirm: handleConfirmChangePrimaryDriver,
          onCancel: handleCancelChangePrimaryDriver,
          defaultSelection: undefined,
        };

  return (
    <>
      <AppBar position='sticky' color='inherit' style={{ boxShadow: 'none' }}>
        <DriverSearchForm search={searchForRenter} />
      </AppBar>
      <MarginWrapper>
        {results && results.length > 0 && (
          <StyledList>
            <Body2 data-testid='result-header'>{`${results.length} ${t('driverSearch.results')}`} </Body2>
            <DividerWithMargin />
            {results.map((renter, index: number) => (
              <DriverSearchResult
                showRenterWarningDialog={handleRenterWarningDialog}
                testId={`driverSearchResult-${index}`}
                searchResult={renter}
                key={index}
                onAddDriver={(renter: SearchResult): Promise<void> => handleAddDriver(renter)}
                phoneTypeDomain={phoneTypeDomain}
              />
            ))}
          </StyledList>
        )}
        {results && results.length === 0 && (
          <Box paddingTop={12}>
            <NoResultsView
              noResultsTitle='driverSearch.noResultsTitle'
              noResultsDescription={
                <Trans
                  i18nKey='driverSearch.noResultsAddInfo'
                  components={{
                    Link: (
                      <StyledLink
                        data-testid={'navigationLink'}
                        onClick={(): void => navigateToDriverForm(driverData)}
                      />
                    ),
                  }}
                />
              }
              isBackgroundColor={false}
            />
          </Box>
        )}
      </MarginWrapper>
      <ProgressOverlay inProgress={loading || isPhoneDomainLoading} />

      <ConfirmationDialog
        open={dialogType !== null}
        onConfirm={dialogConfig.onConfirm}
        onCancel={dialogConfig.onCancel}
        title={dialogConfig.title}
        description={dialogConfig.description}
        options={dialogConfig.options}
        defaultSelection={dialogConfig.defaultSelection}
      />
      {showRenterWarningDialog && (
        <RenterWarningDialog
          searchResult={showRenterWarningDialog}
          onCancel={(): void => {
            setShowRenterWarningDialog(undefined);
            onClose();
          }}
          onSubmit={(): void => {
            handleAddDriver?.(showRenterWarningDialog);
          }}
        />
      )}
    </>
  );
};
