import { Box, styled } from '@mui/material';
import Card from '@mui/material/Card';
import { Body1, EhiButton, ehiTheme } from '@ehi/ui';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

export const PreferencesIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: ${ehiTheme.spacing(1.5)};
`;

export const PreferencesIcon = styled(StickyNote2OutlinedIcon)(() => ({
  '&&': {
    color: '#0000008A',
  },
  size: 'medium',
}));

export const StyledPreferencesCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;

export const PreferencesText = styled(Body1, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'hasPreferences',
})<{ hasPreferences: boolean }>((props) => {
  return {
    padding: ehiTheme.spacing(2),
    color: props.hasPreferences ? 'default' : '#0000008A',
  };
});

export const PreferencesActionButton = styled(EhiButton)`
  margin: 0;
  padding: ${ehiTheme.spacing(2)};
`;

export const AddPreferencesTextBox = styled(Box)`
  margin: ${ehiTheme.spacing(0, 3, 1, 3)};
`;
