import { parseUrn } from 'utils/urnUtils';
import {
  AdditionalInformationBase,
  BillingAccount,
  BusinessAccountSearchResult,
  ContactInformation,
  ReservationAccount,
  ReservationConfiguration,
} from 'services/businessAccount/businessAccountTypes';
import { AdditionalInfoAccountType } from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import {
  getAddressLines,
  getPhoneNumber,
  hasBillingNumberRequiredErrorCodes,
  parseBillingAccountNumber,
} from 'utils/rateAndBillingUtils';
import { EhiErrors, HttpStatusCode } from 'services/types/EhiErrorsTypes';
import { AccountDetailsType } from 'components/flexFlow/rateAndBilling/RateAndBillingTypes';
import { getCityStateZipAddress } from 'utils/addressUtils';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';

export type RateAndBillingInfo = {
  urn?: string;
  number?: string;
  name?: string;
  type?: string;
  reservationConfiguration?: ReservationConfiguration;
  billingAccountUrn?: string;
  billToAccountNumber?: string;
  billingNumber?: string;
  hasBillingAccount?: boolean;
  isBillingNumberRequired?: boolean;
};

export const transformRateSourceInfoFromApi = (
  businessAccount?: ReservationAccount,
  billingAccount?: BillingAccount,
  billingAccountErrors?: EhiErrors | null
): RateAndBillingInfo => {
  let billingAccountExists = false;
  let billingNumberRequired = false;

  const errorCodes = billingAccountErrors?.errors?.map((message) => message.code);

  if (billingAccount && billingAccount.urn) {
    billingAccountExists = true;
    billingNumberRequired = true;
  } else if (billingAccountErrors && billingAccountErrors.status === HttpStatusCode.NotFound) {
    billingAccountExists = false;
    billingNumberRequired = false;
  } else {
    billingAccountExists = true;
    billingNumberRequired = hasBillingNumberRequiredErrorCodes(errorCodes);
  }

  return {
    urn: businessAccount?.urn,
    number: parseUrn(businessAccount?.urn),
    name: businessAccount?.name,
    type: parseUrn(businessAccount?.type),
    reservationConfiguration: businessAccount?.reservationConfiguration,
    billingAccountUrn: billingAccount?.urn,
    billToAccountNumber: parseBillingAccountNumber(billingAccount?.urn),
    billingNumber: billingAccount ? parseUrn(billingAccount.urn) : undefined,
    hasBillingAccount: billingAccountExists,
    isBillingNumberRequired: billingNumberRequired,
  };
};

export const transformAccountDetailsFromRetrieve = (
  account: ReservationAccount | undefined,
  contact: ContactInformation | undefined
): AccountDetailsType => {
  return {
    name: account?.name ?? '',
    accountNumber: parseUrn(account?.urn),
    accountNumberUrn: account?.urn ?? '',
    accountType: parseUrn(account?.type),
    accountAddress1: getAddressLines(contact?.address),
    accountAddress2: getCityStateZipAddress(contact?.address),
    phoneNumber: getPhoneNumber(contact),
  };
};

export const transformRateAndBillingInfo = (
  account: RateAndBillingInfo | undefined,
  contact: ContactInformation | undefined
): AccountDetailsType => {
  return {
    name: account?.name ?? '',
    accountNumber: account?.number ?? '',
    accountNumberUrn: account?.urn ?? '',
    accountType: account?.type ?? '',
    accountAddress1: getAddressLines(contact?.address),
    accountAddress2: getCityStateZipAddress(contact?.address),
    phoneNumber: getPhoneNumber(contact),
  };
};

export const transformAccountDetailsFromSearch = (account: BusinessAccountSearchResult): AccountDetailsType => {
  return {
    name: account?.name ?? '',
    accountNumber: parseUrn(account.urn),
    accountNumberUrn: account.urn ?? '',
    accountType: parseUrn(account.type),
    accountAddress1: getAddressLines(account.mainAddress),
    accountAddress2: getCityStateZipAddress(account.mainAddress),
    phoneNumber: formatPhoneNumber((account as any).mainPhone?.phoneNumber),
  };
};

export const transformAdditionalInformationInfoFromApi = (
  additionalInfo: AdditionalInformationBase[],
  accountType: AdditionalInfoAccountType
): AdditionalInformationBase[] => {
  if (!Array.isArray(additionalInfo)) return [];

  const filteredFields = (): AdditionalInformationBase[] => {
    switch (accountType) {
      case AdditionalInfoAccountType.RATE_SOURCE:
        return additionalInfo.filter((field) => !field.onlyForBusinessPayer);
      case AdditionalInfoAccountType.BILL_TO:
      case AdditionalInfoAccountType.SAME_AS_RATE_SOURCE:
        return additionalInfo;
      default:
        return [];
    }
  };

  return filteredFields();
};
