import { EditorIssue, Equipment, Protection, VehicleClassSelection } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';
import { TranslationKey } from 'components/shared/i18n/i18n';
import { TFunction } from 'i18next';
import { EMPTY_VALUE, RouteSegments } from 'utils/constants';
import { AvailableBookingIssue } from 'hooks/bookingEditor/useRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { To } from 'react-router-dom';
import { HashPaths, RouterPaths } from 'app/router/RouterPaths';
import { DescriptionWithAction } from 'components/shared/alert/AlertDialogTypes';
import { EquipmentType, ProtectionType } from 'services/rentalReference/rentalReferenceTypes';
import { getPeoTitle } from 'utils/peoUtils';
import { uniq } from 'lodash';

export enum VehicleAvailabilityBookingIssues {
  ReservedVehicleNotAvailable = 'BOOK2078',
  PreferredVehicleNotAvailable = 'BOOK2053',
}

export enum BillToBookingIssues {
  BillToInvalidLocation = 'BOOK2082',
  BillToAccountInactive = 'BOOK2083',
}

export enum PeoBookingIssues {
  EquipmentUnavailable = 'BOOK2039',
  ProtectionUnavailable = 'BOOK2034',
}

export const SupportingServiceBookingIssue = 'BOOK1000';
export const ProtectionPriceMismatchBookingIssue = 'BOOK2026';
export const InvalidRateProductBookingIssue = 'BOOK2070';
export const VehicleNotValidForYDBookingIssue = 'BOOK2072';
export const RateProductIsModifiedBookingIssue = 'BOOK2077';
export const DriverNotValidForBrandBookingIssue = 'BOOK2080';

export const hasVehicleAvailabilityBookingIssues = (issues: EditorIssue[]): boolean => {
  return issues?.some((issue: EditorIssue) =>
    Object.values(VehicleAvailabilityBookingIssues).find((vehicleIssue) => vehicleIssue === issue.code)
  );
};

export const hasBillToBookingIssues = (issues: EditorIssue[]): boolean => {
  return issues?.some((issue: EditorIssue) =>
    Object.values(BillToBookingIssues).find((billToIssue) => billToIssue === issue.code)
  );
};

export const hasVehicleYoungDriverBookingIssue = (issues: EditorIssue[]): boolean => {
  return !!issues.find((issue: EditorIssue) => issue.code === VehicleNotValidForYDBookingIssue);
};

export const getVehicleYoungDriverBookingIssue = (t: TFunction, vehicleSipp: string): AvailableBookingIssue => {
  return {
    description: {
      message: t('snackbarMessages.vehicleRemoved', {
        vehicleSipp,
        additionalInfo: t('snackbarMessages.additionalInfo.youngDriver'),
      }),
      routePath: getCorrelatedRoutePathForBookingError(VehicleNotValidForYDBookingIssue),
    },
    bookingIssueCode: VehicleNotValidForYDBookingIssue,
  };
};

export const getVehicleNotAvailableBookingIssue = (
  t: TFunction,
  additionalInfo: TranslationKey,
  vehicleSipp: string,
  issueCode: string
): AvailableBookingIssue | undefined => {
  const vehicleIssueCode = Object.values(VehicleAvailabilityBookingIssues).find((issue) => issue === issueCode);
  if (!vehicleIssueCode) {
    return;
  }
  return {
    description: {
      message: t('snackbarMessages.vehicleRemoved', {
        vehicleSipp,
        additionalInfo: t(additionalInfo),
      }),
      routePath: getCorrelatedRoutePathForBookingError(vehicleIssueCode),
    },
    bookingIssueCode: vehicleIssueCode,
  };
};

export const getAllVehicleNotAvailableBookingIssues = (
  t: TFunction,
  additionalInfo: TranslationKey,
  vehicle: VehicleClassSelection | undefined
): AvailableBookingIssue[] => {
  if (!vehicle) {
    return [];
  }
  return Object.values(VehicleAvailabilityBookingIssues)
    .map((issueCode: VehicleAvailabilityBookingIssues) => {
      if (issueCode === VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable) {
        return getVehicleNotAvailableBookingIssue(
          t,
          additionalInfo,
          vehicle.preferred ? parseUrn(vehicle.preferred) : EMPTY_VALUE,
          VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable
        );
      } else {
        return getVehicleNotAvailableBookingIssue(
          t,
          additionalInfo,
          vehicle.reserved ? parseUrn(vehicle.reserved) : EMPTY_VALUE,
          VehicleAvailabilityBookingIssues.ReservedVehicleNotAvailable
        );
      }
    })
    .filter((bookingIssue: AvailableBookingIssue | undefined) => !!bookingIssue) as AvailableBookingIssue[];
};

export const getAllBillToBookingIssues = (t: TFunction): AvailableBookingIssue[] => {
  return Object.values(BillToBookingIssues)
    .map((issueCode: BillToBookingIssues) => {
      if (issueCode === BillToBookingIssues.BillToInvalidLocation) {
        return {
          description: {
            message: t('snackbarMessages.billToRemoved', {
              additionalInfo: t('snackbarMessages.additionalInfo.notValidForLocation'),
            }),
            routePath: getCorrelatedRoutePathForBookingError(BillToBookingIssues.BillToInvalidLocation),
          },
          bookingIssueCode: BillToBookingIssues.BillToInvalidLocation,
        };
      } else {
        return {
          description: {
            message: t('snackbarMessages.billToRemoved', {
              additionalInfo: t('snackbarMessages.additionalInfo.accountInactive'),
            }),
            routePath: getCorrelatedRoutePathForBookingError(BillToBookingIssues.BillToAccountInactive),
          },
          bookingIssueCode: BillToBookingIssues.BillToAccountInactive,
        };
      }
    })
    .filter((bookingIssue: AvailableBookingIssue | undefined) => !!bookingIssue) as AvailableBookingIssue[];
};

export const hasPeoBookingIssues = (issues: EditorIssue[]): boolean => {
  return issues?.some((issue: EditorIssue) =>
    Object.values(PeoBookingIssues).find((peoIssue) => peoIssue === issue.code)
  );
};

export const getPersistedEquipment = (issues: EditorIssue[], equipment: Equipment[]): string[] => {
  const persistedEquipment: string[] = [];

  equipment.forEach((item: Equipment, index: number) => {
    const equipmentIsUnavailable = !!issues.find((issue) => {
      if (issue.code === PeoBookingIssues.EquipmentUnavailable && issue.paths) {
        const path = issue.paths[0];
        const pathIndex = Number(path.charAt(path.length - 1));
        return pathIndex === index;
      }
      return false;
    });

    if (!!item.type && !equipmentIsUnavailable) {
      persistedEquipment.push(item.type);
    }
  });

  return uniq(persistedEquipment);
};

export const getPersistedProtections = (issues: EditorIssue[], protections: Protection[]): string[] => {
  const persistedProtections: string[] = [];

  protections.forEach((item: Protection, index: number) => {
    const protectionIsUnavailable = !!issues.find((issue) => {
      if (issue.code === PeoBookingIssues.ProtectionUnavailable && issue.paths) {
        const path = issue.paths[0];
        const pathIndex = Number(path.charAt(path.length - 1));
        return pathIndex === index;
      }
      return false;
    });

    if (!!item.type && !protectionIsUnavailable) {
      persistedProtections.push(item.type);
    }
  });

  return uniq(persistedProtections);
};

export const getPeoBookingIssues = (
  t: TFunction,
  additionalInfo: TranslationKey,
  equipment: Equipment[],
  equipmentDetails: EquipmentType[],
  protections: Protection[],
  protectionDetails: ProtectionType[]
): AvailableBookingIssue[] => {
  const peoAlertMessages = new Array<AvailableBookingIssue>();
  equipment.forEach((item, index) => {
    peoAlertMessages.push({
      description: {
        message: t('snackbarMessages.peoRemoved', {
          peoName: getPeoTitle(parseUrn(item.type), equipmentDetails),
          additionalInfo: additionalInfo,
        }),
        routePath: getCorrelatedRoutePathForBookingError(PeoBookingIssues.EquipmentUnavailable),
      },
      bookingIssueCode: PeoBookingIssues.EquipmentUnavailable,
      selectedAddOnIndex: index,
    });
  });
  protections.forEach((item, index) => {
    peoAlertMessages.push({
      description: {
        message: t('snackbarMessages.peoRemoved', {
          peoName: getPeoTitle(parseUrn(item.type), protectionDetails),
          additionalInfo: additionalInfo,
        }),
        routePath: getCorrelatedRoutePathForBookingError(PeoBookingIssues.ProtectionUnavailable),
      },
      bookingIssueCode: PeoBookingIssues.ProtectionUnavailable,
      selectedAddOnIndex: index,
    });
  });

  return peoAlertMessages;
};

export const hasDriverBookingIssue = (issues: EditorIssue[]): boolean => {
  return !!issues.find((issue: EditorIssue) => issue.code === DriverNotValidForBrandBookingIssue);
};

export const getDriverProfileBookingIssue = (t: TFunction): AvailableBookingIssue => {
  return {
    description: {
      message: t('snackbarMessages.loyaltyProfileRemoved', {
        additionalInfo: t('snackbarMessages.additionalInfo.notValidForBrand'),
      }),
      routePath: getCorrelatedRoutePathForBookingError(DriverNotValidForBrandBookingIssue),
    },
    bookingIssueCode: DriverNotValidForBrandBookingIssue,
  };
};

export const getEhiMessagesAsSnackbarMessages = (
  ehiMessages: ResponseMessage[],
  excludedBookingIssues: string[]
): DescriptionWithAction[] => {
  const filteredMessages = ehiMessages.filter((message) => !excludedBookingIssues.includes(message.code));
  return filteredMessages.map((message): DescriptionWithAction | undefined => {
    if (message.supportInformation) {
      return { message: message.supportInformation };
    } else {
      return { message: message.localizedMessage ?? EMPTY_VALUE };
    }
  }) as DescriptionWithAction[];
};

export const getEhiIssuesAsSnackbarMessages = (
  editorIssues: EditorIssue[],
  availableBookingIssues: AvailableBookingIssue[]
): DescriptionWithAction[] => {
  const containsVehicleIssue = availableBookingIssues.find((issue) => {
    const isVehicleNotAvailableIssue =
      (issue.bookingIssueCode === VehicleAvailabilityBookingIssues.ReservedVehicleNotAvailable ||
        issue.bookingIssueCode === VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable) &&
      hasVehicleAvailabilityBookingIssues(editorIssues);
    const isVehicleYDIssue =
      issue.bookingIssueCode === VehicleNotValidForYDBookingIssue && hasVehicleYoungDriverBookingIssue(editorIssues);
    return isVehicleNotAvailableIssue || isVehicleYDIssue;
  });

  return availableBookingIssues
    .filter((issue) => {
      const isPeoIssue =
        issue.bookingIssueCode === PeoBookingIssues.EquipmentUnavailable ||
        issue.bookingIssueCode === PeoBookingIssues.ProtectionUnavailable;
      // All add-ons get removed if there is a vehicle issue otherwise booking throws a pricing error if we persist add-ons
      // This will hide Add-on snackbar message in this case since the add-on can still be available for the given data (i.e. location)
      if (isPeoIssue && containsVehicleIssue) {
        return false;
      }

      const validEditorIssues = editorIssues.filter((editorIssue) => editorIssue.code === issue.bookingIssueCode);
      // When we build the snackbar messages out by page, we create a message for all selected add-ons
      // Not all the add-ons will be removed, so we need to exclude the add-ons that are not tied to a booking issue
      const shouldExcludePeoMessage = !validEditorIssues.find((editorIssue) => {
        if (hasPeoBookingIssues([editorIssue]) && editorIssue.paths) {
          const path = editorIssue.paths[0];
          const pathIndex = Number(path.charAt(path.length - 1));
          return pathIndex === issue.selectedAddOnIndex;
        }
        return true;
      });

      return validEditorIssues.length > 0 && !shouldExcludePeoMessage;
    })
    .map((issue) => issue.description);
};

const DRIVER_ISSUE_CODES = ['BOOK2063', 'BOOK2057', 'BOOK2051', 'BOOK2080'];
const WHEN_AND_WHERE_ISSUE_CODES = [
  'BOOK2044',
  'BOOK2045',
  'BOOK2046',
  'BOOK2047',
  'BOOK2048',
  'BOOK2049',
  'BOOK2050',
  'BOOK2075',
];
export const AdditionalInfoReqBookingIssue = 'BOOK2054';

const VEHICLE_ISSUE_CODES = ['BOOK2052', 'BOOK2053', 'BOOK2072', 'BOOK2078'];
const RATE_AND_BILL_ISSUE_CODES = ['BOOK2082'];
const ADDITIONAL_INFO_ISSUE_CODES = [AdditionalInfoReqBookingIssue];

export const INCOMPLETE_RES_OVERRIDE_ERROR_CODES = [
  'BOOK2049',
  'BOOK2047',
  'BOOK2050',
  'BOOK2052',
  'BOOK2078',
  'BOOK2053',
];
export const CAN_OVERRIDE_ERROR_CODES = new Set(INCOMPLETE_RES_OVERRIDE_ERROR_CODES);

export const getCorrelatedRoutePathForBookingError = (
  bookingIssueCode: string,
  isModify = false,
  reservation = ''
): To | undefined => {
  const baseUrl = RouteSegments.BASE_URL;
  const modifySegment = isModify ? RouteSegments.MODIFY(reservation) : EMPTY_VALUE;
  const equipmentTab = '?peoTab=EquipmentTab';
  const protectionsTab = '?peoTab=ProtectionsTab';

  if (DRIVER_ISSUE_CODES.includes(bookingIssueCode)) {
    return { pathname: baseUrl + RouterPaths.Driver, hash: HashPaths.AddDriver };
  } else if (WHEN_AND_WHERE_ISSUE_CODES.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.WhenAndWhere;
  } else if (VEHICLE_ISSUE_CODES.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.Vehicle;
  } else if (PeoBookingIssues.EquipmentUnavailable.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.AddOns + equipmentTab;
  } else if (PeoBookingIssues.ProtectionUnavailable.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.AddOns + protectionsTab;
  } else if (RATE_AND_BILL_ISSUE_CODES.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.RateAndBill;
  } else if (ADDITIONAL_INFO_ISSUE_CODES.includes(bookingIssueCode)) {
    return { pathname: baseUrl + modifySegment + RouterPaths.RateAndBill, hash: HashPaths.EditAdditionalInfo };
  }
  return undefined;
};
