import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';

export enum ConsentFields {
  Country = 'country',
  PhoneNumber = 'phoneNumber',
  Status = 'status',
}

export enum ConsentStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Revoked = 'REVOKED',
}

export interface ConsentFieldValues {
  [ConsentFields.Country]: string;
  [ConsentFields.PhoneNumber]: string;
  [ConsentFields.Status]: string;
}

export type ConsentProps = {
  driverDetails: DriverData;
};
