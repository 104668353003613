import { FC, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import TextField from '@mui/material/TextField';
import { TextAreaFieldProps } from 'components/shared/forms/FormFieldTypes';
import FormHelperText from '@mui/material/FormHelperText';
import { Caption, ehiTheme, Subtitle1 } from '@ehi/ui';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { ERROR_ALERT_VARIANT } from 'components/shared/alert/AlertDialogTypes';
import { Box } from '@mui/system';

export const TextAreaField: FC<TextAreaFieldProps> = ({
  header,
  displayHeader = true,
  name,
  maxChars,
  rows,
  marginBottom = '0rem',
  gridPadding = 0,
  validateOnBlur = false,
  errorIconMarginBottom = 31.5,
  errorIconMarginRight = 1.0,
}) => {
  const { control, trigger } = useFormContext();

  const calculateCharCount = (charLength: number): number => {
    return charLength ? charLength : 0;
  };

  return (
    <GridContainer>
      {displayHeader && (
        <GridItem sm={12} style={{ paddingTop: 0 }}>
          <Subtitle1 bold>{header}</Subtitle1>
        </GridItem>
      )}
      <GridItem sm={12} style={{ padding: gridPadding }}>
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }): ReactElement => (
            <div style={{ marginBottom: marginBottom }}>
              <TextField
                {...field}
                fullWidth
                value={field.value}
                id={name}
                placeholder={header}
                multiline
                rows={rows}
                error={!!fieldState.error}
                InputProps={{
                  endAdornment: !!fieldState.error?.message && (
                    <div
                      style={{
                        marginBottom: ehiTheme.spacing(errorIconMarginBottom),
                        marginRight: ehiTheme.spacing(errorIconMarginRight),
                      }}>
                      <ErrorOutlinedIcon color={ERROR_ALERT_VARIANT} fontSize={'medium'} />
                    </div>
                  ),
                }}
                inputProps={{
                  maxLength: maxChars,
                  'aria-label': field.name,
                  'data-testid': field.name,
                }}
                onBlur={async (): Promise<void> => {
                  if (validateOnBlur) {
                    await trigger(name);
                  }
                }}
              />
              <FormHelperText
                style={{ padding: ehiTheme.spacing(0, 2), display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  component='span'
                  display={'flex'}
                  alignItems={'center'}
                  style={{ visibility: fieldState.error?.message ? 'visible' : 'hidden' }}>
                  <ErrorOutlinedIcon
                    fontSize={'small'}
                    color={ERROR_ALERT_VARIANT}
                    style={{ paddingRight: ehiTheme.spacing(1) }}
                  />
                  <Caption data-testid='errorMessage' color={'#B00020'}>
                    {fieldState.error?.message}
                  </Caption>
                </Box>
                <Caption data-testid='remainingChars'>
                  {calculateCharCount(field.value?.length)}/{maxChars}
                </Caption>
              </FormHelperText>
            </div>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};
