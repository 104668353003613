import { Body2, EhiButton, ehiTheme, H6 } from '@ehi/ui';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { FC, useMemo, useState } from 'react';
import { BranchV2 } from 'services/location/locationTypes';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import { RentalLocationAddress } from './RentalLocationAddress';
import { combineDateAndTime, isInvalidDateTime, toDateTimeString } from 'utils/dateUtils';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import { WhenAndWhereFields } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { selectIsReadOnlyFlow, selectLengthOfRental, selectOneWay } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { BranchLookupModal } from './branchLookup/BranchLookupModal';
import { useDriverLicenseValidation } from 'hooks/driverLicenseValidation/useDriverLicenseValidation';

type RentalReturnSectionProps = {
  returnBranch: BranchV2 | undefined;
  returnDateTime: string | undefined;
  startDate: DateTime | undefined;
  handleRentalReturnLocation: (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ) => void;
};

export const RentalReturnSection: FC<RentalReturnSectionProps> = ({
  returnBranch,
  returnDateTime,
  startDate,
  handleRentalReturnLocation,
}) => {
  const { t } = useTranslations();
  const { setValue, getValues, trigger, setError } = useFormContext();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const lengthOfRental = useAppSelector(selectLengthOfRental);
  const isOneWay = useAppSelector(selectOneWay);
  const [showReturnLocationModal, setShowReturnLocationModal] = useState(false);
  const [returnLocationTimezone] = useWatch({ name: [WhenAndWhereFields.ReturnLocationTimezone] });
  const formattedReturnDate = useMemo(
    () => toDateTimeString(returnDateTime, t('format.shortWeekdayDate'), returnBranch?.timezone),
    [returnBranch?.timezone, returnDateTime, t]
  );
  const formattedReturnTime = useMemo(
    () => toDateTimeString(returnDateTime, t('format.timeWithZone'), returnBranch?.timezone),
    [returnBranch?.timezone, returnDateTime, t]
  );
  const { validateRentalDatesAgainstLicenseExpiration } = useDriverLicenseValidation();

  const combineDateTime = (date: DateTime | '', time: DateTime | ''): void => {
    if (isInvalidDateTime(time)) {
      setError(WhenAndWhereFields.ReturnTime, { message: t('validation.invalidTimeFormat') });
    } else {
      const combined = !!date && !!time ? combineDateAndTime(date, time, returnBranch?.timezone) : '';
      setValue(WhenAndWhereFields.ReturnDateTime, combined);
    }
  };

  const handleDateChange = async (date: DateTime | ''): Promise<void> => {
    setValue(WhenAndWhereFields.ReturnDate, date);
    await trigger([WhenAndWhereFields.StartDate, WhenAndWhereFields.ReturnTime]);

    const time = getValues(WhenAndWhereFields.ReturnTime);
    combineDateTime(date, time);
    validateRentalDatesAgainstLicenseExpiration(getValues(WhenAndWhereFields.StartDate), date.toString());
  };

  const handleTimeChange = async (time: DateTime | ''): Promise<void> => {
    await trigger([WhenAndWhereFields.ReturnDate]);
    const date = getValues(WhenAndWhereFields.ReturnDate);
    setValue(WhenAndWhereFields.ReturnTime, time);
    combineDateTime(date, time);
  };

  return (
    <GridContainer data-testid={'rentalReturnSection'}>
      {isReadOnly ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} sx={{ padding: ehiTheme.spacing(0) }}>
            <Caption2 display={'block'}>
              {t('whenWhere.rentalReturn')}
              {isOneWay && (
                <IconButton data-testid='oneWayIcon' style={{ padding: 0 }}>
                  <ArrowRightAlt fontSize='small' color='secondary' />
                </IconButton>
              )}
            </Caption2>
            <Box data-testid='returnDate'>
              <Body2 bold display='inline' noWrap={true}>
                {formattedReturnDate}{' '}
              </Body2>
              <Body2 display='inline' noWrap={true}>
                {formattedReturnTime}
              </Body2>
              <Body2 display={'inline'} data-testid={'lengthOfRental'}>
                {` (${lengthOfRental}${lengthOfRental === 1 ? t('whenWhere.day') : t('whenWhere.days')})`}
              </Body2>
            </Box>
            {returnBranch && <RentalLocationAddress branch={returnBranch} />}
          </Grid>
        </Grid>
      ) : (
        <Grid container gap={ehiTheme.spacing(3)} flexDirection={'row'}>
          <Grid container gap={ehiTheme.spacing(0.5)} data-testid={'rentalReturnLocation'}>
            <Grid item>
              <H6 data-testid={'rentalReturnHeader'}>{t('whenWhere.rentalReturn')}</H6>
            </Grid>
            <Grid container flexDirection={'column'}>
              <Grid container flexDirection={'row'} gap={ehiTheme.spacing(0.5)} alignItems={'center'}>
                <Grid item>
                  <Caption2 data-testid={'rentalReturnLocationLabel'}>
                    {t('whenWhere.returnLocation')}
                    {isOneWay && (
                      <IconButton data-testid='oneWayIcon' style={{ padding: 0 }}>
                        <ArrowRightAlt fontSize='small' color='secondary' />
                      </IconButton>
                    )}
                  </Caption2>
                </Grid>
                <Grid item>
                  <EhiButton
                    sx={{ margin: 0, padding: ehiTheme.spacing(1.5, 1) }}
                    data-testid={'rentalReturnLocationEdit'}
                    onClick={(): void => setShowReturnLocationModal(true)}>
                    {t('common.edit')}
                  </EhiButton>
                </Grid>
              </Grid>
              {returnBranch && <RentalLocationAddress branch={returnBranch} />}
            </Grid>
          </Grid>

          <Grid container gap={ehiTheme.spacing(1)} flexDirection={'row'}>
            <Grid
              item
              xs={12}
              sm={12}
              md
              data-testid={'rentalReturnDatePicker'}
              style={{ padding: ehiTheme.spacing(0) }}>
              <DatePickerField
                disablePast
                disabled={!returnBranch}
                minDate={startDate}
                name={WhenAndWhereFields.ReturnDate}
                label={t('whenWhere.date')}
                submitOnChange={handleDateChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md data-testid={'rentalReturnTimePicker'} sx={{ padding: ehiTheme.spacing(0) }}>
              {/* returnLocationTimezone is set as undefined as the form is loading after the RentalReturnSection component, so reading the timezone from returnBranch*/}
              <TimePickerField
                id={'rentalReturnTime'}
                disabled={!returnBranch}
                name={WhenAndWhereFields.ReturnTime}
                label={t('whenWhere.time')}
                submitOnChange={handleTimeChange}
                onSubmitTime={handleTimeChange}
                timezone={returnLocationTimezone ?? (returnBranch && returnBranch?.timezone)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {showReturnLocationModal && (
        <BranchLookupModal
          open={showReturnLocationModal}
          pickupOrDropOffLocation={WhenAndWhereFields.ReturnLocation}
          handleCancel={(): void => setShowReturnLocationModal(false)}
          title={t('whenWhere.branchLookup')}
          handleApply={(
            branchUrn: string,
            stationId: string,
            locationCurrentTime: DateTime | undefined,
            timezone?: string
          ): void => {
            setShowReturnLocationModal(false);
            handleRentalReturnLocation(branchUrn, stationId, locationCurrentTime, timezone);
          }}
        />
      )}
    </GridContainer>
  );
};
