export const EMPTY_VALUE = '';
export const MAX_PAGE_SIZE = 50;
export const DOUBLE_DASH = '--';
export const EHI_MESSAGES_HEADER = 'ehi-messages';
export const EHI_ETAG_HEADER = 'etag';
export const FORWARD_SLASH = '/';

export const CALLING_APP_ID = 12;
export const COUNTRY_CODE_US = 'USA';
export const COUNTRY_CODE_CA = 'CAN';
export const ISO2_COUNTRY_CODE_US = 'US';

export enum PrimaryAppBarAction {
  MENU = 'MENU',
  BACK = 'BACK',
}

// Custom Events
export const MESSAGE_EVENT_SET_LOCATION = 'locationSet';

export enum Brand {
  ENTERPRISE = 'ENTERPRISE',
  ALAMO = 'ALAMO',
  NATIONAL = 'NATIONAL',
  ATESA = 'ATESA',
  CITER = 'CITER',
  ET = 'ET',
  AL = 'AL',
  ZL = 'ZL',
}

export enum LoyaltyProgram {
  ALAMO_INSIDERS = 'ALAMO_INSIDERS',
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  EMERALD_CLUB = 'EMERALD_CLUB',
  ONE_CLUB = 'ONE_CLUB',
  NON_LOYALTY = 'NON_LOYALTY',
}

export const DRIVERS_LICENSE_LEGAL_ID_TYPE = 'DRIVERS_LICENSE';
export const ID_CARD_LEGAL_ID_TYPE = 'ID_CARD';
export const PASSPORT_LEGAL_ID_TYPE = 'PASSPORT';

export const DVLA = 'DVLA';

export const LICENSEE = 'LICENSEE';
export const NO_SHOW = 'NO_SHOW';
export const FULFILLED = 'FULFILLED';
export const DEBIT_CARD = 'DEBIT_CARD';
export const BANK_CARD = 'BANKCARD';
export const DEBT = 'DEBT';
export const CANCELED = 'CANCELED';

export const BILL_TO = 'billTo';
export const ADDITIONAL_INFO = 'additionalInfo';

export const PAYLATER = 'PAYLATER';

export const BASE_URL = '../';
const RES = 'res';
export const CREATE = 'create';
export const MODIFY = 'modify';

export const RouteSegments = {
  MODIFY: (reservation: string): string => `/${RES}/${reservation}/${MODIFY}/`,
  BASE_URL: BASE_URL,
};

export const ENTER_KEY_CODE = 13;
export const ARROW_DOWN_KEY = 'ArrowDown';
export const ARROW_UP_KEY = 'ArrowUp';
export const TAB_KEY = 'Tab';
export const ENTER_KEY = 'Enter';

export const NOTES = 'notes';
export const PINNED = 'pinned';
export const HISTORY = 'history';
export const ACTION_NOTE = 'action';

export const NON_CANCELABLE_STATUSES = [NO_SHOW, CANCELED, FULFILLED];
