import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { DateTime } from 'luxon';

export const verifyRequiredFields = (driver: DriverData): boolean => {
  const nameVerified = !!driver.firstName && !!driver.lastName;

  const driverDOBFormat = 'yyyy-MM-dd';
  const DOB = driver.dob && DateTime.fromFormat(driver.dob, driverDOBFormat);
  const DOBVerified = !!DOB && !!DOB.year && !!DOB.month && !!DOB.day;

  const addressVerified =
    !!driver?.address?.lines.length &&
    !!driver.address.city &&
    !!driver.address.countrySubdivisionCode &&
    !!driver.address.countryCode &&
    !!driver.address.postalCode;

  return nameVerified && addressVerified && DOBVerified;
};
