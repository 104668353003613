import { AnySchema, object, string, AnyObject, ObjectSchema } from 'yup';
import {
  AdditionalInfoData,
  BillToAdditionalInfoData,
  FieldType,
  SaveAdditionalInfoAccountData,
  SaveBillToAccountAdditionalInfo,
  SaveRateSourceAccountAdditionalInfo,
  UIAdditionalInfoField,
} from './AdditionalInfoTypes';
import { parseUrn } from 'utils/urnUtils';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { TFunction } from 'i18next';
import { ActionOptions } from '@ehi/ui';
import { DateTime } from 'luxon';
import { toDateTime } from 'utils/dateUtils';
import { AdditionalInformationBase } from 'services/businessAccount/businessAccountTypes';
import { AdditionalInformation } from 'services/booking/bookingTypes';

export const validationSchema = (
  additionalInfoData: AdditionalInfoData[],
  t: TFunction<'translation'>
): ObjectSchema<
  {
    [x: string]: string;
  },
  AnyObject,
  {
    [x: string]: string;
  },
  ''
> => {
  return object().shape(
    additionalInfoData.reduce((schema, info) => {
      if (info.additionalInfoFields) {
        info.additionalInfoFields.forEach((field: UIAdditionalInfoField) => {
          let validator = string();
          if (field.isRequiredAtReservation) {
            validator = validator.required(t('validation.requiredField'));
          }
          schema[`${info.accountNumber}_${parseUrn(field.fieldId)}`] = validator;
        });
      }
      return schema;
    }, {} as Record<string, AnySchema>)
  );
};

export const getActions = (
  onClose: (actions: SelectedAction) => void,
  onSubmit: () => Promise<void>,
  t: TFunction<'translation'>
): ActionOptions => {
  return {
    primaryAction: {
      label: t('common.save'),
      onClick: onSubmit,
    },
    secondaryAction: {
      label: t('common.cancel'),
      onClick: () => onClose(SelectedAction.Secondary),
    },
  };
};

export const getInitialValues = (
  additionalInfo: AdditionalInfoData[],
  t: TFunction<'translation'>
): Record<string, string | DateTime<boolean> | undefined> => {
  return additionalInfo.reduce(
    (allFields: Record<string, string | DateTime<boolean> | undefined>, accountAdditionalInfo: AdditionalInfoData) => {
      accountAdditionalInfo.additionalInfoFields.forEach((field) => {
        allFields[`${accountAdditionalInfo.accountNumber}_${parseUrn(field.fieldId)}`] =
          field.dataType === FieldType.DATE && field.fieldValue
            ? toDateTime(DateTime.fromFormat(field.fieldValue, t('format.monthDayYearWithNoSpecialCharacters')).toISO())
            : field.fieldValue ?? '';
      });
      return allFields;
    },
    {} as Record<string, string>
  );
};

export const generateSaveRequests = (
  values: Record<string, string | DateTime<boolean> | undefined>,
  additionalInfo: AdditionalInfoData[],
  t: TFunction<'translation'>
): SaveAdditionalInfoAccountData[] => {
  const combinedAdditionalInfoFieldRequests = (
    accounts: SaveAdditionalInfoAccountData[],
    currentValue: SaveRateSourceAccountAdditionalInfo | SaveBillToAccountAdditionalInfo
  ): SaveAdditionalInfoAccountData[] => {
    const currentIndex = accounts.findIndex((item) => item.accountNumber === currentValue.accountNumber);
    if (currentIndex > -1) {
      const existingAccount = accounts[currentIndex];
      if (existingAccount.fields) {
        existingAccount.fields.push(...currentValue.fields);
      }
    } else {
      accounts.push(currentValue);
    }
    return accounts;
  };

  return Object.entries(values)
    .map(([key, value]) => {
      const [accountNumber, fieldId] = key.split('_');
      const accountData = additionalInfo?.find((x) => x.accountNumber === accountNumber);
      const fieldData = accountData?.additionalInfoFields.find((x) => parseUrn(x.fieldId) === fieldId);
      const isDateField = fieldData?.dataType === FieldType.DATE;
      if (accountData && fieldData) {
        return {
          accountNumber: accountNumber,
          accountUrn: accountData.accountUrn,
          billingNumber: (accountData as BillToAdditionalInfoData).billingNumber || '',
          type: accountData.type,
          ordinal: 'ordinal' in accountData ? accountData.ordinal : undefined,
          fields: [
            {
              fieldId: fieldData.fieldId,
              value:
                isDateField && value
                  ? DateTime.fromISO(value as string).toFormat(t('format.monthDayYearWithNoSpecialCharacters'))
                  : value,
              isOnlyForBusinessPayer: fieldData.isOnlyForBusinessPayer,
            },
          ].filter((field) => field.value),
        } as SaveAdditionalInfoAccountData;
      }
      return undefined; // Return undefined if accountData or fieldData is not found
    })
    .filter((item): item is SaveAdditionalInfoAccountData => item !== undefined && item.fields.length > 0)
    .reduce(combinedAdditionalInfoFieldRequests, []);
};

export const isBillingSameAsRateSourceAndHasAdditionalInfo = (
  accountNumber: string,
  billingAccountNumber: string,
  billingAdditionalInformation?: AdditionalInformation[]
): boolean => {
  return !!(
    billingAccountNumber === parseUrn(accountNumber) &&
    billingAdditionalInformation &&
    billingAdditionalInformation?.length > 0
  );
};

type MappedAdditionalInfoType = {
  fieldId?: string;
  value?: string;
  onlyForBusinessPayer: boolean;
};

export const mapEditorAdditionalInfo = (
  accountAdditionalInfo?: AdditionalInformationBase[],
  editorBillingAdditionalInfo?: AdditionalInformation[]
): MappedAdditionalInfoType[] | undefined => {
  if (!editorBillingAdditionalInfo || !accountAdditionalInfo) {
    return undefined;
  }
  const mappedData = editorBillingAdditionalInfo.reduce<MappedAdditionalInfoType[]>((acc, editorData) => {
    const match = accountAdditionalInfo.find((additionalInfo) => additionalInfo.urn === editorData.fieldId);
    if (match) {
      acc.push({
        fieldId: editorData.fieldId,
        value: editorData.value,
        onlyForBusinessPayer: match.onlyForBusinessPayer,
      });
    }
    return acc;
  }, []);

  return mappedData.length > 0 ? mappedData : undefined;
};
