import { RefObject } from 'react';
import { TextFieldProps } from '@mui/material';
import { FieldValues, Path } from 'react-hook-form';
import { Option } from 'components/shared/ui/OptionTypes';

export type EhiTextFieldProps = TextFieldProps & {
  fieldRef?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null;
  overrideReadOnly?: boolean;
};

export type FormTextFieldProps<TFieldValues extends FieldValues = FieldValues> = TextFieldProps & {
  name: Path<TFieldValues>;
};

export type PhoneTextFieldProps = TextFieldProps & {
  name: string;
  country?: string;
  allowClearing?: boolean;
};

export type TextAreaFieldProps = {
  header: string;
  displayHeader?: boolean;
  name: string;
  maxChars: number;
  rows: number;
  marginBottom?: string;
  gridPadding?: number;
  validateOnBlur?: boolean;
  errorIconMarginBottom?: number;
  errorIconMarginRight?: number;
};

export type OptionList = { subHeader?: string; list: OptionItem[] };
export type OptionItem = { id: string; label: string };

export enum SelectionVariant {
  CHECKBOX,
  CHECKMARK,
  RADIO,
  NONE,
}

export type SelectDropDownProps = {
  formFieldName: string;
  label: string;
  primaryList: OptionList;
  secondaryList?: OptionList;
  selectionVariant?: SelectionVariant;
  defaultSelection?: string;
  required?: boolean;
  allToggleLabel?: string;
  inputAllText?: string;
  searchFieldPlaceholder?: string;
  onClose?: () => void;
  footerText?: string;
  textColor?: string;
  autoFocus?: boolean;
};

/**
 * A re-usable text input that supports phone number formatting. These inputs will automatically
 * format the value that is visible on the text field but will update the form state with the
 * value that has no formatting whatsoever. For example, while the user will see (999) 888-9999
 * the form value will equal 9998889999 because in most cases APIs and data stores do not want
 * any formatted values, they prefer the raw values.
 *
 * @param country {string} ISO2 or ISO3 country code used to select phone format
 * @param allowClearing
 * @param props
 */

export type SelectFieldProps<TOptions = unknown> = FormTextFieldProps & {
  options: Option<TOptions>[];
  hasNoneOption?: boolean;
  hasAllOption?: boolean;
  formControlProps?: Record<string, any>;
};

export type MaxLengthTextFieldProps = FormTextFieldProps & { maxLength?: number };
