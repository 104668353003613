import { Box, Grid, Tooltip } from '@mui/material';
import { Body1, ehiTheme } from '@ehi/ui';
import { ConsentRadioGroup } from 'components/flexFlow/driver/consent/ConsentRadioGroup';
import { FormProvider, useForm } from 'react-hook-form';
import { ChangeEvent, FC, ReactElement, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Section } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { ConsentProps, ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { consentInitialValues } from 'components/flexFlow/driver/consent/consentUtils';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useRetrieveConsentSearchQuery } from 'services/consent/consentCommunicationQueries';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectReservationData } from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';

import { GridItem } from 'components/shared/ui/styles/Grid.styles';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { CheckboxField } from 'components/shared/forms/CheckboxField';
import { DOUBLE_DASH } from 'utils/constants';
import { useReservationFlow } from 'hooks/useReservationFlow';
import InfoIcon from '@mui/icons-material/Info';

export const ConsentSection: FC<ConsentProps> = ({ driverDetails }) => {
  const { t } = useTranslations();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const { isCreateFlow, isModifyFlow } = useReservationFlow();
  const reservation = useAppSelector(selectReservationData);
  const resNumber = reservation ? parseUrn(reservation?.reservation) : '';
  const formMethods = useForm({
    defaultValues: consentInitialValues,
  });
  const { data: consentData, isFetching } = useRetrieveConsentSearchQuery(resNumber);
  const [open, setOpen] = useState<boolean>(false);

  const handleCheckboxChange = (value: number): void => {
    console.log('value:', value);
  };

  const getConsentDataStatus = useMemo(() => {
    const consentStatus = (consentData && consentData[0]?.status) ?? undefined;
    switch (consentStatus) {
      case ConsentStatus.Accepted:
        return (
          <>
            <Body1 data-testid='consent-status'>{t('consent.accepted')}</Body1>
            <Body1 data-testid='consent-phoneNumber'>{consentData ? consentData[0]?.phoneNumber : '--'}</Body1>
          </>
        );
      case ConsentStatus.Declined:
        return <Body1 data-testid='consent-status'>{t('consent.declined')}</Body1>;
      case ConsentStatus.Revoked:
        return <Body1 data-testid='consent-status'>{t('consent.revoked')}</Body1>;
      default:
        return <Body1 data-testid='consent-status'> {DOUBLE_DASH} </Body1>;
    }
  }, [consentData, t]);

  const handleTooltipClose = (): void => {
    setOpen(false);
  };

  const handleTooltipOpen = (): void => {
    setOpen(true);
  };

  const consentQuestion = (): ReactElement => {
    return (
      <Grid container item alignItems={'stretch'}>
        <Body1 data-testid={'consent-question'}>{t('consent.question')}</Body1>
        <div>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            onOpen={(): void => setOpen(true)}
            disableInteractive
            title={t('consent.infoIconMessage')}
            placement='top'
            sx={{ color: ehiTheme.palette.primary.main, paddingLeft: ehiTheme.spacing(0.5) }}
            arrow
            slotProps={{
              popper: {
                disablePortal: true,
              },
            }}>
            <InfoIcon onClick={handleTooltipOpen} style={{ cursor: 'pointer' }} />
          </Tooltip>
        </div>
      </Grid>
    );
  };

  return (
    <>
      {readOnly && (
        <Box>
          <Grid item xs={12}>
            <Caption2>{t('consent.consentStatus')}</Caption2>
            {getConsentDataStatus}
          </Grid>
        </Box>
      )}
      {isCreateFlow && (
        <Section data-testid={'consentSection'}>
          <FormProvider {...formMethods}>
            <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(2)}>
              {consentQuestion()}
              <Caption2 marginTop={ehiTheme.spacing(1)} marginBottom={ehiTheme.spacing(-1)}>
                {t('consent.consentStatus')}
              </Caption2>
              <ConsentRadioGroup driverDetails={driverDetails} />
            </Box>
          </FormProvider>
        </Section>
      )}
      {isModifyFlow && (
        <Box>
          <Section data-testid={'consentSection'}>
            <FormProvider {...formMethods}>
              <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(2)}>
                {consentQuestion()}
                <Caption2 marginTop={ehiTheme.spacing(1)} marginBottom={ehiTheme.spacing(-1)}>
                  {t('consent.consentStatus')}
                </Caption2>
                <Grid container item alignItems={'stretch'} spacing={2} marginTop={-2} marginLeft={-1}>
                  <GridItem>
                    <ConsentRadioGroup driverDetails={driverDetails} />
                  </GridItem>
                  <GridItem>
                    <CheckboxField
                      name={'consent_updatePhone'}
                      data-testid={'consent-update-checkbox'}
                      label={t('consent.updatePhone')}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        handleCheckboxChange(e.target.checked ? 1 : 0);
                      }}
                    />
                  </GridItem>
                </Grid>
              </Box>
            </FormProvider>
          </Section>
        </Box>
      )}
      <ProgressOverlay inProgress={isFetching} />
    </>
  );
};
