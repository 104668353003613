import { FC, useCallback, useMemo } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import {
  StyledResultsLink,
  StyledRunButton,
  VerificationItems,
  VerificationNotPassIcon,
} from 'components/flexFlow/driver/driverVerification/DriverVerification.styles';
import { useAppSelector } from 'redux/hooks';
import { selectReservationData } from 'redux/selectors/bookingEditor';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { useAlert } from 'components/shared/alert/AlertContext';
import { BulletedItem, BulletedList } from 'components/shared/ui/styles/Global.styles';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { verifyRequiredFields } from 'components/flexFlow/driver/driverVerification/driverVerificationUtils';
import { NoteBanner } from 'components/shared/banner/NoteBanner';

export type DriverVerificationType = {
  driverDetails: DriverData;
  readOnly: boolean;
};

export const DriverVerification: FC<DriverVerificationType> = ({ driverDetails, readOnly }) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { determineTransactionType } = useReservationSessionHelper();
  const reservationData = useAppSelector(selectReservationData);

  const transactionType =
    reservationData?.lifecycle &&
    reservationData?.status &&
    determineTransactionType(reservationData.lifecycle, reservationData.status);

  const resultsLinkDisabled = useMemo(() => {
    return transactionType === TransactionTypes.View;
  }, [transactionType]);

  const hasRequiredFields = useMemo(() => {
    return verifyRequiredFields(driverDetails);
  }, [driverDetails]);

  const handleNoteBannerLinkClick = useCallback(async (): Promise<void> => {
    await showAlert({
      title: t('driver.driverVerification.seeDetailsTitle'),
      subtitle: t('driver.driverVerification.requiredForChecks'),
      description: (
        <BulletedList data-testid='requiredFieldsList' sx={{ color: '#000000DE', paddingTop: 0 }}>
          <BulletedItem sx={{ margin: 0 }}>{t('driver.driverVerification.addressRequired')}</BulletedItem>
          <BulletedItem sx={{ margin: 0 }}>{t('driver.driverVerification.DOBRequired')}</BulletedItem>
        </BulletedList>
      ),
    });
  }, [showAlert, t]);

  return (
    <Box data-testid='driverVerificationSection' display={'flex'} flexDirection={'column'} gap={ehiTheme.spacing(0.5)}>
      <Caption2 data-testid='driverVerificationHeader'>{t('driver.driverVerification.checks')}</Caption2>
      <VerificationItems data-testid='driverVerificationItems'>
        <VerificationNotPassIcon fontSize='small' />
        <StyledResultsLink data-testid='driverVerificationResultsLink' disabled={resultsLinkDisabled}>
          {t('driver.driverVerification.renterVerification')}
        </StyledResultsLink>
        {!readOnly && (
          <StyledRunButton data-testid='driverVerificationRunButton' variant='text'>
            {t('driver.driverVerification.run')}
          </StyledRunButton>
        )}
      </VerificationItems>
      {!hasRequiredFields && (
        <NoteBanner
          description={t('driver.driverVerification.additionInformationRequired')}
          handleLinkClick={handleNoteBannerLinkClick}
        />
      )}
    </Box>
  );
};
