import { FC, ReactElement, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { useTranslations } from 'components/shared/i18n';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { ALL_VALUE, SelectField } from 'components/shared/forms/SelectField';
import {
  MINIMUM_PASSENGER_NUM,
  passengerNumberOptions,
  vehicleSearchInitialValues,
} from 'components/flexFlow/vehicle/vehicleSearch/vehicleSearchUtils';
import {
  StyledResetButton,
  VehicleSearchGridContainer,
  VehicleSearchGridItem,
  VehicleSearchHeader,
} from 'components/flexFlow/vehicle/VehiclePage.styles';
import { VehicleAutoCompleteField } from 'components/shared/forms/VehicleAutoCompleteField';
import { VehicleSearchFields, VehicleSectionProps } from 'components/flexFlow/vehicle/VehicleTypes';
import { ExpandMore } from '@mui/icons-material';
import { ehiTheme, Subtitle1 } from '@ehi/ui';

export const VehicleSearchForm: FC<VehicleSectionProps> = ({ vehicles, isContentVisible, onToggleContent }) => {
  const { t } = useTranslations();
  const { reset, resetField, setValue } = useFormContext();

  const [carClass, carType, fuelType, passengerNumber] = useWatch({
    name: [
      VehicleSearchFields.VehicleClass,
      VehicleSearchFields.VehicleType,
      VehicleSearchFields.FuelType,
      VehicleSearchFields.PassengerNumber,
    ],
  });

  const handleReset = (event: React.MouseEvent): void => {
    event.stopPropagation();
    reset({ ...vehicleSearchInitialValues });
  };

  const renderResetButton = (): ReactElement => {
    if (isContentVisible) {
      return (
        <StyledResetButton type='reset' onClick={handleReset}>
          {t('common.reset')}
        </StyledResetButton>
      );
    } else {
      return (
        <StyledResetButton type='button'>
          <ExpandMore style={{ marginLeft: ehiTheme.spacing(1) }} />
        </StyledResetButton>
      );
    }
  };

  const vehicleTypeOptions = useMemo(
    () => [
      { label: t('vehicle.cars'), value: 'cars' },
      { label: t('vehicle.suvs'), value: 'suvs' },
      { label: t('vehicle.trucks'), value: 'trucks' },
      { label: t('vehicle.vans'), value: 'vans' },
    ],
    [t]
  );

  const fuelTypeOptions = useMemo(
    () => [
      { label: t('vehicle.gasoline'), value: '169' },
      { label: t('vehicle.diesel'), value: '171' },
      { label: t('vehicle.hybrid'), value: '168' },
      { label: t('vehicle.electric'), value: '170' },
    ],
    [t]
  );

  return (
    <>
      <VehicleSearchHeader onClick={onToggleContent}>
        <Subtitle1 bold>{t('vehicle.vehicleClassSearch')}</Subtitle1>
        {renderResetButton()}
      </VehicleSearchHeader>
      {isContentVisible && (
        <GridContainer gap={ehiTheme.spacing(2)}>
          <VehicleSearchGridItem sm={12}>
            <VehicleAutoCompleteField
              vehicles={vehicles}
              setVehicle={(vehicle): void => setValue(VehicleSearchFields.VehicleClass, vehicle)}
              resetVehicle={(): void => resetField(VehicleSearchFields.VehicleClass)}
              selectedVehicleClass={carClass}
              disabled={
                carType != ALL_VALUE || fuelType != ALL_VALUE || passengerNumber != MINIMUM_PASSENGER_NUM.toString()
              }
            />
          </VehicleSearchGridItem>
          <VehicleSearchGridItem sm={12}>
            <EhiDivider />
          </VehicleSearchGridItem>
          <VehicleSearchGridContainer sm={12} gap={ehiTheme.spacing(2)}>
            <VehicleSearchGridItem sm={4}>
              <SelectField
                name={VehicleSearchFields.VehicleType}
                label={t('vehicle.vehicleType')}
                data-testid={'vehicleTypeDropdown'}
                disabled={!!carClass}
                options={vehicleTypeOptions}
                hasNoneOption={false}
                hasAllOption
                onChange={(event: any): void => {
                  setValue(VehicleSearchFields.VehicleType, event.target.value);
                }}
              />
            </VehicleSearchGridItem>
            <VehicleSearchGridItem sm={4}>
              <SelectField
                name={VehicleSearchFields.FuelType}
                label={t('vehicle.fuelType')}
                data-testid={'fuelTypeDropdown'}
                disabled={!!carClass}
                options={fuelTypeOptions}
                hasNoneOption={false}
                hasAllOption
                onChange={(event: any): void => {
                  setValue(VehicleSearchFields.FuelType, event.target.value);
                }}
              />
            </VehicleSearchGridItem>
            <VehicleSearchGridItem sm={4}>
              <SelectField
                name={VehicleSearchFields.PassengerNumber}
                label={t('vehicle.passengerNumber')}
                data-testid={'passengerNumberDropdown'}
                disabled={!!carClass}
                options={passengerNumberOptions}
                hasNoneOption={false}
                onChange={(event: any): void => {
                  setValue(VehicleSearchFields.PassengerNumber, event.target.value);
                }}
              />
            </VehicleSearchGridItem>
          </VehicleSearchGridContainer>
        </GridContainer>
      )}
    </>
  );
};
