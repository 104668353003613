import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, Body2, ehiTheme, H6, Subtitle1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import {
  selectBillingAccountPayer,
  selectIsReadOnlyFlow,
  selectPayers,
  selectPickup,
} from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { EHI_DOMAINS, parseUrn, parseUrnItem } from 'utils/urnUtils';
import { useMemo, useState } from 'react';
import { SavingFormProvider } from 'context/saveAction/SavingFormProvider';
import { useForm } from 'react-hook-form';
import { PaymentFields, PaymentValues } from 'components/flexFlow/payment/PaymentTypes';
import { useSavePayment } from 'components/flexFlow/payment/useSavePayment';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';
import { getPaymentTypeOptions } from 'utils/paymentUtils';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { Box } from '@mui/system';
import {
  BillToItem,
  BillToItemsContainer,
  PaymentFlexFlowCard,
  PaymentPage,
  PaymentScrollContainer,
  PaymentSectionBox,
} from 'components/flexFlow/payment/Payment.styles';
import { useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { useRetrieveBranchEffectivePolicies } from 'services/location/locationQueries';
import { PaymentDetails } from 'components/flexFlow/payment/PaymentDetails';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';

export const Payment: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const { save } = useSavePayment();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const payers = useAppSelector(selectPayers);
  const billingAccount = useAppSelector(selectBillingAccountPayer);

  const [loading, setLoading] = useState(false);
  const pickupData = useAppSelector(selectPickup);
  const { data: policies } = useRetrieveBranchEffectivePolicies(parseUrn(pickupData?.branch), t);
  const { driverData, isRenterProfileLoading } = useGetRenterProfile();

  const paymentOptions = useMemo((): OptionItem[] => {
    return getPaymentTypeOptions(t, policies);
  }, [policies, t]);

  const { data: billToAccount } = useBusinessAccountQuery(
    parseUrnItem(billingAccount ?? '', EHI_DOMAINS.businessAccount.account)
  );

  const formMethods = useForm<PaymentValues>({
    defaultValues: {
      paymentType: parseUrn(payers?.person?.paymentMethod) ?? EMPTY_VALUE,
    },
    resolver: undefined,
  });

  const selectedPaymentType = formMethods.getValues(PaymentFields.PaymentType);

  const selectedValue = useMemo((): OptionItem | '' => {
    return paymentOptions.find((value) => value?.id === selectedPaymentType) ?? EMPTY_VALUE;
  }, [selectedPaymentType, paymentOptions]);

  const showPaymentDetails = !!selectedValue && !isRenterProfileLoading && driverData;

  const handleSubmit = async (values: PaymentValues): Promise<ServiceResultType> => {
    setLoading(true);
    return save(values).finally(() => {
      setLoading(false);
    });
  };

  return (
    <PaymentPage>
      <PaymentFlexFlowCard loadingState={mapLoadingState(false, false)}>
        <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
        <PaymentScrollContainer>
          <SavingFormProvider
            {...formMethods}
            submitFn={(values: PaymentValues): Promise<ServiceResultType> => handleSubmit(values)}>
            <FlexiFlowCardInnerContainer style={{ padding: ehiTheme.spacing(0), margin: ehiTheme.spacing(0) }}>
              {billToAccount && (
                <>
                  <PaymentSectionBox data-testid='billToSection'>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <H6>{t('rateAndBilling.billTo.pageTitle')}</H6>
                    </Box>
                    <BillToItemsContainer>
                      <BillToItem>
                        <Subtitle1 bold>{billToAccount?.name}</Subtitle1>
                        <Body2>{t('payment.directBill')}</Body2>
                      </BillToItem>
                    </BillToItemsContainer>
                  </PaymentSectionBox>
                  <EhiDivider />
                </>
              )}
              <PaymentSectionBox>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <H6>{t('common.renter')}</H6>
                </Box>
                <Box my={3}>
                  {readOnly ? (
                    <>
                      <Caption2>{t('payment.paymentMethod')}</Caption2>
                      <Body1 data-testid='selectedPaymentMethod'>
                        {selectedValue && selectedValue?.label ? selectedValue?.label : DOUBLE_DASH}
                      </Body1>
                    </>
                  ) : (
                    <SelectMenu
                      formFieldName={PaymentFields.PaymentType}
                      autoFocus={true}
                      label={t('payment.paymentMethod')}
                      data-testid='paymentDropDown'
                      primaryList={{
                        list: paymentOptions,
                      }}
                      textColor={'#4D789D'}
                    />
                  )}
                </Box>
                {showPaymentDetails && <PaymentDetails selectedValue={selectedValue} driverData={driverData} />}
              </PaymentSectionBox>
              <ProgressOverlay inProgress={loading} />
            </FlexiFlowCardInnerContainer>
          </SavingFormProvider>
        </PaymentScrollContainer>
      </PaymentFlexFlowCard>
    </PaymentPage>
  );
};
