import { UseQueryResult } from '@tanstack/react-query';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { retrieveConsentCommunication } from 'services/consent/consentCommunicationService';
import { ConsentSms } from 'services/consent/consentCommunicationTypes';
import { useResScopeQuery } from 'context/queryClient/queryUtil';

const CONSENT_SEARCH_QUERY_KEY = 'consentSearchQueryKey';

export const useRetrieveConsentSearchQuery = (
  reservationNumber: string
): UseQueryResult<Array<ConsentSms>, EhiErrors> => {
  return useResScopeQuery({
    queryKey: [CONSENT_SEARCH_QUERY_KEY, reservationNumber],
    queryFn: (): Promise<Array<ConsentSms>> => retrieveConsentCommunication(reservationNumber),
    enabled: !!reservationNumber,
  });
};
