// Headers
export const AUTHORIZATION = 'Authorization';
export const EHI_LOCALE = 'Ehi-locale';
export const EHI_CALLING_APPLICATION = 'Ehi-Calling-Application';
export const EHI_CALLER_ID = 'Ehi-Caller-Id';
export const EHI_DEVICE_LOCATION_ID = 'Ehi-Device-Location-Id';
export const EHI_WORKFLOW_ID = 'Ehi-Workflow-Id';
export const EHI_REQUEST_ID = 'Ehi-Request-Id';
export const EHI_ORIGIN_APPLICATION = 'Ehi-Origin-Application';
export const EHI_ACCEPT_PROTECTED_DATA = 'Ehi-Accept-Protected-Data';
export const SPAN_ID = 'X-B3-SpanId';
export const TRACE_ID = 'X-B3-TraceId';
export const ACCEPT = 'Accept';
export const CONTENT_TYPE = 'Content-Type';
export const TIMEZONE = 'Ehi-timezone';
export const ORIGIN_APP_VERSION = 'Origin-App-Version';
export const EHI_LOYALTY_PROGRAM = 'Ehi-Loyalty-Program';
export const IF_MATCH = 'if-match';

// Header values
export const ACCEPT_APPLICATION_JSON = 'application/json';
export const CONTENT_TYPE_APPLICATION_JSON = 'application/json;charset=UTF-8';
export const CONTENT_TYPE_TEXT_PLAIN = 'text/plain;charset=UTF-8';
export const CALLING_APPLICATION = 'client:com.ehi.rental.booking.reservation.greenlight';

export const PROTECTED_DATA_VALUE = 'ALL';
