import { Box, styled } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { StyledLink } from 'components/shared/ui/styles/Global.styles';

export const VerificationItems = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${ehiTheme.spacing(1)};
`;

export const StyledResultsLink = styled(StyledLink, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled?: boolean;
}>(({ disabled = false }) => {
  const color = disabled ? '#00000061' : ehiTheme.palette.primary.main;
  const cursor = disabled ? 'default' : 'pointer';
  return {
    textDecoration: 'underline',
    cursor,
    color,
  };
});

export const StyledRunButton = styled(EhiButton)`
  padding: ${ehiTheme.spacing(0.5, 0, 0.5, 0)};
  margin: ${ehiTheme.spacing(0, 0, 0, -2)};
`;

export const VerificationNotPassIcon = styled(CircleOutlinedIcon)`
  color: #0000008a;
`;
