import { useAppSelector } from 'redux/hooks';
import {
  selectBillingAccountAdditionalInformation,
  selectBillingAccountPayer,
  selectBookingEditorId,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useCallback } from 'react';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { BusinessPayer, EditorBusinessPayer, RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource, updateBusinessPayers } from 'services/booking/bookingService';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { ACCOUNT_TYPE_NEGOTIATED, parseBillingAccountNumber } from 'utils/rateAndBillingUtils';
import {
  getAllVehicleNotAvailableBookingIssues,
  hasVehicleAvailabilityBookingIssues,
  RateProductIsModifiedBookingIssue,
} from 'utils/bookingUtils';
import { useTranslations } from 'components/shared/i18n';
import { useAdditionalInformationQuery } from 'services/businessAccount/accountQueries';
import { BillingAccountType } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import {
  isBillingSameAsRateSourceAndHasAdditionalInfo,
  mapEditorAdditionalInfo,
} from 'components/flexFlow/rateAndBilling/additionalInformation/additionalInformationUtils';
import { useReservationFlow } from 'hooks/useReservationFlow';

export type AddOrModifyRateSourceProps = {
  accountNumber: string;
  ratePlanId?: string;
  handleCloseModal: () => void;
};

export type useRateAndBillingHookReturn = {
  addOrModifyRateSource: (props: AddOrModifyRateSourceProps) => Promise<void>;
};

export const useRateSource = (): useRateAndBillingHookReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const { isModifyFlow } = useReservationFlow();
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleRemoveVehicle } = useBookingIssue();
  const billingAdditionalInformation = useAppSelector(selectBillingAccountAdditionalInformation);
  const billingAccountPayer = useAppSelector(selectBillingAccountPayer);
  const billingAccountNumber = parseBillingAccountNumber(billingAccountPayer);
  const { data: billingAccountAdditionalInfo } = useAdditionalInformationQuery(billingAccountNumber ?? '');

  const addOrModifyRateSource = useCallback(
    async ({ accountNumber, handleCloseModal, ratePlanId }: AddOrModifyRateSourceProps): Promise<void> => {
      let rateSourceAdditionalInformation = undefined;
      let billToAdditionalInformation = undefined;
      const responseMessages = [];
      if (
        isBillingSameAsRateSourceAndHasAdditionalInfo(accountNumber, billingAccountNumber, billingAdditionalInformation)
      ) {
        const mappedAdditionalData = mapEditorAdditionalInfo(
          billingAccountAdditionalInfo,
          billingAdditionalInformation
        );
        rateSourceAdditionalInformation = mappedAdditionalData?.filter((item) => !item.onlyForBusinessPayer);
        billToAdditionalInformation = mappedAdditionalData?.filter((item) => item.onlyForBusinessPayer);
      }

      const rateSourceRequestBody: RateSource = {
        type: ACCOUNT_TYPE_NEGOTIATED,
        account: accountNumber,
        ratePlan: ratePlanId ?? undefined,
        additionalInformation:
          rateSourceAdditionalInformation && rateSourceAdditionalInformation?.length > 0
            ? rateSourceAdditionalInformation
            : undefined,
      };
      const { data, errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody), {
        excludedBookingIssues: isModifyFlow ? [RateProductIsModifiedBookingIssue] : [],
        availableBookingIssues: [
          ...getAllVehicleNotAvailableBookingIssues(t, 'snackbarMessages.additionalInfo.account', vehicle),
        ],
      });
      if (errors) {
        responseMessages.push(...errors);
      }
      if (billToAdditionalInformation && billToAdditionalInformation?.length > 0) {
        const requestBody: EditorBusinessPayer[] = [
          {
            type: BillingAccountType.BILLING_ACCOUNT,
            billingAccount: billingAccountPayer,
            additionalInformation: billToAdditionalInformation,
          } as BusinessPayer,
        ];

        const { errors: billingErrors } = await updateAndRefresh(() =>
          updateBusinessPayers(bookingEditorId, requestBody)
        );
        if (billingErrors) {
          responseMessages.push(...billingErrors);
        }
      }
      if (responseMessages.length === 0) {
        handleCloseModal();
        if (data?.issue && hasVehicleAvailabilityBookingIssues(data.issue)) {
          await handleRemoveVehicle(data.issue);
        }
      } else {
        await showAlert({
          variant: 'error',
          description: responseMessages.map((message) => message.localizedMessage || '').toString(),
        });
      }
    },
    [
      updateAndRefresh,
      isModifyFlow,
      t,
      vehicle,
      bookingEditorId,
      handleRemoveVehicle,
      showAlert,
      billingAccountNumber,
      billingAccountPayer,
      billingAccountAdditionalInfo,
      billingAdditionalInformation,
    ]
  );

  return {
    addOrModifyRateSource,
  };
};
