import { FC, Fragment, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashPaths } from 'app/router/RouterPaths';
import { Body1, ehiTheme, H6 } from '@ehi/ui';
import { Box, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslations } from 'components/shared/i18n';
import {
  AdditionalInfoCaption,
  AdditionalInformationBox,
  RateAndBillingSubSectionBox,
  RateAndBillingTitleBox,
  StyledEhiEditButton,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { DOUBLE_DASH } from 'utils/constants';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { AdditionalInfoData } from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { EditAdditionalInfoDialog } from './additionalInformation/EditAdditionalInfoDialog';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useRetrieveAdditionalInformation } from './additionalInformation/useRetrieveAdditionalInformation';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

export const AdditionalInformationSection: FC = () => {
  const { additionalInfo: accountAdditionalInformation, loading } = useRetrieveAdditionalInformation();
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const hasAdditionalInformation = useMemo(
    (): boolean => accountAdditionalInformation?.length > 0,
    [accountAdditionalInformation]
  );

  const { hash } = useLocation();
  const navigate = useNavigate();

  const handleOnClick = (): void => {
    navigate({ hash: HashPaths.EditAdditionalInfo }, { replace: true });
  };

  const handleCloseDialog = (): void => {
    navigate({ hash: '' }, { replace: true });
  };

  return (
    <>
      {hasAdditionalInformation ? (
        <>
          <EhiDivider />
          <RateAndBillingSubSectionBox>
            <RateAndBillingTitleBox>
              <H6 bold data-testid='additional-info-title'>
                {t('rateAndBilling.additionalInformation')}
              </H6>
              {!isReadOnly && (
                <Box>
                  <StyledEhiEditButton
                    size='small'
                    variant='text'
                    onClick={handleOnClick}
                    data-testid='addtional-info-edit-button'>
                    {t('common.edit')}
                  </StyledEhiEditButton>
                </Box>
              )}
            </RateAndBillingTitleBox>
            <AdditionalInformationBox data-testid='additional-information-container'>
              {accountAdditionalInformation.map((additionalInfo: AdditionalInfoData, index: number) => {
                if (!additionalInfo.additionalInfoFields.length) {
                  return null;
                }

                return (
                  <Fragment key={index}>
                    {index !== 0 && <EhiDivider />}
                    <AdditionalInformationCard additionalInfo={additionalInfo} />
                  </Fragment>
                );
              })}
            </AdditionalInformationBox>
            {hash === HashPaths.EditAdditionalInfo && (
              <EditAdditionalInfoDialog
                additionalInfoData={accountAdditionalInformation}
                open={true}
                onClose={handleCloseDialog}
              />
            )}
          </RateAndBillingSubSectionBox>
        </>
      ) : null}
      <ProgressOverlay inProgress={!!loading} />
    </>
  );
};

export const AdditionalInformationCard: FC<{ additionalInfo: AdditionalInfoData }> = ({ additionalInfo }) => {
  const showCaret = additionalInfo.additionalInfoFields.length > 3;

  const [isExpanded, setIsExpanded] = useState<boolean>(showCaret);

  const toggleExpand = (): void => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box style={{ margin: ehiTheme.spacing(1.2, 0) }}>
      <Grid container display={'flex'} alignItems={'center'}>
        <Grid item xs={9} sm={9}>
          <Box display={'flex'} alignItems={'center'}>
            <H6 bold data-testid='additional-info-accountName'>
              {additionalInfo.accountName}
            </H6>
          </Box>
        </Grid>
        {showCaret && (
          <Grid item xs={3} sm={3} display={'flex'} justifyContent={'flex-end'}>
            <IconButton data-testid='toggle-expand-button' onClick={toggleExpand}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} data-testid='additional-info-fields'>
        {additionalInfo?.additionalInfoFields
          ?.slice(0, showCaret && !isExpanded ? 3 : additionalInfo?.additionalInfoFields?.length)
          .map((field) => (
            <Grid item xs={12} sm={4} key={field.fieldId} data-testid={`info-field-${field.fieldId}`}>
              <Box>
                <AdditionalInfoCaption data-testid={`info-caption-${field.fieldId}`}>
                  {field?.fieldName}
                  {field.isRequiredAtReservation && <span>*</span>}
                </AdditionalInfoCaption>
                <Body1 data-testid={`info-helper-text-${field.fieldId}`}>
                  {field.fieldValue ? field.fieldValue : DOUBLE_DASH}
                </Body1>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
