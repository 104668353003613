import { H6 } from '@ehi/ui';
import { Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectRateSource } from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';
import {
  RateAndBillingLabel,
  RateAndBillingSubSectionBox,
  RateAndBillingText,
  RateAndBillingTitleBox,
  StyledEhiEditButton,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { RateSourceInfo } from 'components/flexFlow/rateAndBilling/RateSourceInfo';
import { FC, useState } from 'react';
import { EditRateSourceDialog } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialog';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { AccountSearchProvider } from 'context/accountSearch/AccountSearchContext';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { useGetRatePlanById } from 'services/ratePlan/ratePlanQueries';

export const RateSourceSection: FC = () => {
  const { t } = useTranslations();
  const [showRateSourceModal, setShowRateSourceModal] = useState(false);
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const { data: ratePlanDetails, isFetching: isRatePlanFetching } = useGetRatePlanById(
    parseUrn(negotiatedRateSource?.ratePlan)
  );

  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const {
    rateSourceInfo,
    rateSourceAccountNumber,
    editorRateSource,
    isLoading: isRateSourceInfoLoading,
  } = useRateSourceAccountDetails();
  const rateProduct = parseUrn(editorRateSource?.rateProduct);

  return (
    <RateAndBillingSubSectionBox data-testid='rateSourceSection'>
      <RateAndBillingTitleBox>
        <H6>{t('rateAndBilling.rateSource')}</H6>
        {!isReadOnly && (
          <StyledEhiEditButton onClick={(): void => setShowRateSourceModal(true)}>
            {t('common.edit')}
          </StyledEhiEditButton>
        )}
      </RateAndBillingTitleBox>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
          {rateSourceAccountNumber && (
            <RateAndBillingText data-testid='textBody'>{rateSourceAccountNumber}</RateAndBillingText>
          )}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='rateProduct'>
          {ratePlanDetails?.name ? (
            <>
              <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.rateSourceRatePlan')}</RateAndBillingLabel>
              <RateAndBillingText data-testid='textBody'>{ratePlanDetails?.name}</RateAndBillingText>
            </>
          ) : (
            <>
              <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.rateProduct')}</RateAndBillingLabel>
              {rateProduct && <RateAndBillingText data-testid='textBody'>{rateProduct}</RateAndBillingText>}
            </>
          )}
        </Grid>
        <RateSourceInfo accountName={rateSourceInfo?.name} accountType={rateSourceInfo?.type} />
      </Grid>
      {showRateSourceModal && (
        <AccountSearchProvider>
          <EditRateSourceDialog closeModal={(): void => setShowRateSourceModal(false)} />
        </AccountSearchProvider>
      )}
      <ProgressOverlay inProgress={(isRateSourceInfoLoading || isRatePlanFetching) ?? false} />
    </RateAndBillingSubSectionBox>
  );
};
